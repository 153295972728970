import classnames from "classnames";


import React from "react";

import { useSelector } from "react-redux";

import { useAppSelector } from "@web-solutions/store/types"

import { Title } from "@web-solutions/core/ui-elements";

import { LString, t, tm, TM } from "@web-solutions/module-localization";

import { PaymentModalType } from "core/constants/remote-config";

import { useRemoteConfig } from "core/hooks/use-remote-config";
import { useTimer } from "core/payment/hooks/use-timer";
import { ProductDetails } from "core/store/billing/selectors";


import EmailEditor from "../../components/edit-email-field";

import { Prices } from "./components/prices";

import { SimpleView } from "./components/simple-view";
import { getActualPrices } from "./components/simple-view/utilts";
import { TrialInfoBlock } from "./components/trial-info-block";

import classes from "./style.module.scss";

const tKey = "core.payment_popup";

interface ProductInfoProps {
  product: ProductDetails;
  subtitle?: React.ReactNode;
  isSimpleView?: boolean;
}

export const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  subtitle,
  isSimpleView,
}) => {
  const { title, textLineThrough, discount, isOneTimePurchase, isTrial } =
    product;

  const {
    paymentModalHeader,
    showPricesBlock,
    totalPriceType,
    paymentModalType,
    promocodeOffer,
    paymentModalEmailEnabled,
    simpleTitleModal,
    trialInfoBlock,
    paymentPopup,
  } = useRemoteConfig();

  const promocodeActivated = useSelector(
    (state: any) => state.billing.promocodeActivated,
  );

  const locale = useAppSelector((state) => state.locale.locale);

  const { vat, vatPrice, price, fullPrice, actualPrice } = getActualPrices({
    activeProduct: product,
    totalPriceType,
  });
  const titleText = product.title?.[locale] || "";

  const timer = useTimer();
  const isShownTimerBlock = !!discount && timer?.shown;

  const isSimpleModal = paymentModalType === PaymentModalType.SIMPLE;
  const isSimpleTitleModal = paymentModalType === PaymentModalType.SIMPLE_TITLE;

  const paymentModalHeaderParams = { titleText, ...product };

  return (
    <div
      className={classnames(classes.wrap, { [classes.simple]: isSimpleView })}
    >
      {isOneTimePurchase
? (
        <div className={classes.row}>
          <p className={classes.aboutItemBlackText}>
            {tm(title?.[locale as keyof LString], "")}
          </p>
          <p className={classes.aboutItemBlackText}>
            {!!textLineThrough && (
              <span className={classes.lineThrough}>
                {tm(textLineThrough, "", product)}
              </span>
            )}
            {price}
          </p>
        </div>
      )
: (
        <>
          {!(isSimpleModal || isSimpleTitleModal)
? (
            <>
              <div className={classes.block}>
                <div className={classes.headerWrapper}>
                  {paymentModalHeader?.title && (
                    <Title level="h3" className={classes.modalTitle}>
                      <TM
                        k={paymentModalHeader.title}
                        tOptions={paymentModalHeaderParams}
                      />
                    </Title>
                  )}
                  <p className={classes.modalSubtitle}>
                    {tm(
                      paymentModalHeader?.subtitle,
                      paymentPopup.selectedTitles[locale],
                      paymentModalHeaderParams,
                    )}
                  </p>
                </div>
                {subtitle}
                {(promocodeActivated ||
                  (!promocodeOffer.enabled &&
                    promocodeOffer.switchDefaultValue)) && (
                  <div className={classes.promocode}>
                    <span>🎁</span>
                    {t(`${tKey}.promocode.text`)}
                  </div>
                )}
                {paymentModalType === PaymentModalType.BOTTOM_INJECTED &&
                  discount && (
                    <div className={classes.specialOffer}>
                      <div className={classes.title}>
                        {t(`${tKey}.modal_discount.title`)}
                      </div>
                      <div className={classes.text}>
                        {t(`${tKey}.modal_discount.text`, { discount })}
                      </div>
                    </div>
                  )}
                {isShownTimerBlock && (
                  <div className={classes.row}>
                    <p className={classes.discountTitle}>
                      {t(
                        `${tKey}.${timer?.shown ? (discount ? "discount" : "discount_zero") : "discount_save"}`,
                        { discount },
                      )}
                    </p>
                    {timer?.shown && (
                      <div className={classes.discount}>{timer?.text}</div>
                    )}
                  </div>
                )}
              </div>

              <div className={classes.divider} />

              {showPricesBlock && (
                <Prices product={product} priceText={price} />
              )}
            </>
          )
: (
            <>
              <SimpleView
                showPrice={!simpleTitleModal?.withTotalPriceUnder}
                fullPrice={fullPrice}
                actualPrice={actualPrice}
                discount={discount}
                vatPrice={vatPrice}
                vat={vat}
                discountPrice={product?.savedPrice || ""}
                mode={isSimpleTitleModal ? simpleTitleModal?.type : undefined}
              />
            </>
          )}
          {paymentModalEmailEnabled && (
            <>
              <div className={classes.divider} />
              <EmailEditor isSimpleView={isSimpleView} />
            </>
          )}
          {trialInfoBlock.show && isTrial && (
            <TrialInfoBlock
              product={product}
              periodMode={trialInfoBlock.periodMode}
            />
          )}
        </>
      )}
    </div>
  );
};
