// @ts-nocheck
import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'

import { UPSALE } from "@web-solutions/core/upsale/store/types"

import { PaymentSystem } from '@web-solutions/react-billing';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';
import { reInit as reInitRemoteConfig } from '@web-solutions/core/store/remote-config/actions'
import { initAppLink } from '@web-solutions/base-app/store/app/actions';
import { getRouteAfterFlow, setCompleted, setStep } from '@web-solutions/base-app/store/routing/actions';
import { useRemoteConfig } from '@web-solutions/base-app/hooks/use-remote-config';
import { REDIRECT_TO_CHROME, doRedirectToChrome } from '@web-solutions/core/utils/redirect-to-chrome';

import { AFTER_PURCHASE_ROUTES, ROUTES, SUBSCRIPTION } from 'src/constants/routes';
import { SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { OFFERS_BY_PAGES, type OfferProductId } from 'src/constants/upsale';
import { selectFlow } from 'src/store/routing/selectors'
import { preloadPage } from 'src/utils/route';

interface Params {
  onCompleted?: () => void
}

export const useQuizNavigation = ({ onCompleted }: Params = {}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const store = useStore();

  const { afterPurchaseFlow, redirectToChrome, upsaleFlowMode } = useRemoteConfig();
  const getRoute = useCallback((step: number) => {
    const flow = selectFlow(store.getState());

    preloadPage(flow[step + 1]);
    preloadPage(flow[step + 2]);

    return ROUTES ? ROUTES[flow[step]] : '/' + flow[step];
  }, [store])

  const IS_BRAINARY_PROJECT=process.env.REACT_APP_APP_NAME ==='virtual-brainary'

  const nextScreen = useCallback(async () => {
    const { routing: { isCompleted, currentStep } } = store.getState();
    const flow = selectFlow(store.getState());
    const step = currentStep + 1;

    if (currentStep === 0) {
      window.fbq('track', 'Lead');

      IS_BRAINARY_PROJECT && window.ttq.track('SubmitForm')
    }

    window.scrollBy({
      top: -window.innerHeight,
      behavior: 'smooth'
    });

    if (currentStep === 0 && redirectToChrome === REDIRECT_TO_CHROME.ON_CLICK) {
      doRedirectToChrome(redirectToChrome);
    }

    if (step === flow.length || isCompleted) {
      if (!isCompleted) {
        dispatch(setCompleted(true));
        await dispatch(reInitRemoteConfig());
        dispatch(initAppLink());
        stringifyUrlParams({ completed: null });
        onCompleted && onCompleted()
      }
      //@ts-ignore
      navigate(dispatch(getRouteAfterFlow()));
    }
    else {
      dispatch(setStep(step));
      navigate(getRoute(step));
    }
  }, [store, redirectToChrome, navigate, onCompleted, dispatch, getRoute])


  const setPrevStep = useCallback(() => {
    const { routing: { currentStep } } = store.getState()
    const step = currentStep > 0 ? currentStep - 1 : 0;
    dispatch(setStep(step));
  }, [store, dispatch]);

  const prevScreen = useCallback(() => {
    const { routing: { currentStep } } = store.getState()
    const step = currentStep > 0 ? currentStep - 1 : 0;

    dispatch(setStep(step));

    navigate(getRoute(step));
  }, [dispatch, getRoute, navigate, store])

  const isBought = useCallback((product: OfferProductId | OfferProductId[], oneTimePurchases: { product_code: OfferProductId }[]) => {
    if (Array.isArray(product)) {
      return oneTimePurchases?.some(item => product.includes(item.product_code))
    }
    return oneTimePurchases?.some(item => product === item.product_code);
  }, []);

  const getRouteAfterPurchased = useCallback(() => {
    const { billing: { purchase, oneTimePurchases } } = store.getState()

    const paymentSystem = purchase?.paymentSystem;
    if (paymentSystem) {
      return upsaleFlowMode ? UPSALE.MAIN : SUBSCRIPTION.SUCCESS;
    }

    const existedFlow = afterPurchaseFlow.length ? afterPurchaseFlow.filter((item) => AFTER_PURCHASE_ROUTES.includes(item)) : [];

    const flow = [SUBSCRIPTION_NAMES.MAIN, ...existedFlow];
    const currentStep = location.pathname;

    let nextStepIndex = flow.findIndex((route) => currentStep === SUBSCRIPTION[route]) + 1

    const isSkipScreen = () => {
      const isOneTimeOffer = isBought(OFFERS_BY_PAGES[flow[nextStepIndex]]!, oneTimePurchases);

      return isOneTimeOffer
    }

    while (isSkipScreen() && flow[nextStepIndex]) {
      nextStepIndex += 1
    }

    preloadPage(flow[nextStepIndex + 1])
    preloadPage(flow[nextStepIndex + 2])

    const step = upsaleFlowMode ? UPSALE.MAIN : SUBSCRIPTION.SUCCESS

    const nextStep = flow[nextStepIndex] ? flow[nextStepIndex] : step;

    return SUBSCRIPTION[nextStep];

  }, [afterPurchaseFlow, store, location.pathname, isBought,]);

  const goToUpsaleMain = useCallback(() => {
    navigate(UPSALE.MAIN);
  }, [navigate]);

  return {
    nextScreen,
    prevScreen,
    setPrevStep,
    getRouteAfterPurchased,
    goToUpsaleMain
  }
}
