import { FloatButton } from "antd";

import DeviceProps from '@magnus/react-native-device-props';

export const sendEmail = async (email: string) => {
    const apiKey = '181c517ca6cb7faf15d2d69625f14554-2e68d0fb-3c53e290';
    const domain = 'mg.myjoygames.com';
    const url = `https://api.mailgun.net/v3/${domain}/messages`;
    const idfm = await DeviceProps.getIDFM();

    const formData = new FormData();
    formData.append('to', email);
    formData.append('template', `admin_2`);
    formData.append('v:idfm', idfm);
    formData.append('cc', 'no-reply@myjoygames.com');

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Basic ${btoa(`api:${apiKey}`)}`,
        },
        body: formData,
    });

    return response.json();
};


const IdfmButton = () => {
    const onClick = async () => {
        const email = prompt('Введите email:');
        if (email) await sendEmail(email)
    }

    return <FloatButton
        onClick={onClick}
        description='idfm'
        shape="square"
    />
}

export default IdfmButton



