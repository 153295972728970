import lazy, { PreloadableComponent } from 'react-lazy-with-preload';

import { QUIZ } from '@web-solutions/store/types';

import { SUBSCRIPTION, UPSALE } from 'src/constants/routes';

export const QUIZ_SCREENS = {
  [QUIZ.EMAIL]: lazy(() => import('src/screens/quiz/email')),
  [QUIZ.MAGIC]: lazy(() => import('src/screens/quiz/magic')),
  [QUIZ.WELCOME]: lazy(() => import('src/screens/quiz/welcome')),
  [QUIZ.WELCOME_IMAGE]: lazy(() => import('src/screens/quiz/welcome-image')),
  [QUIZ.WELCOME_GENDER]: lazy(() => import('src/screens/quiz/welcome-gender')),
  [QUIZ.GENDER]: lazy(() => import('src/screens/quiz/gender')),
  [QUIZ.VIDEO_RESULT]: lazy(
    () => import('@web-solutions/core/video/components/video-result-screen')
  ),
  [QUIZ.TAKE_PHOTO]: lazy(() => import('src/screens/quiz/take-photo')),
};

export const SUBSCRIPTION_SCREENS = {
  [SUBSCRIPTION.MAIN]: lazy(() => import('src/screens/subscription/main')),
  [SUBSCRIPTION.SUCCESS]: lazy(() => import('src/screens/subscription/success')),
};

export const UPSALE_SCREENS = {
  [UPSALE.MAIN]: lazy(() => import('src/screens/upsale')),
};

export const SCREENS: Record<string, PreloadableComponent<React.FC<any>>> = {
  ...QUIZ_SCREENS,
  ...UPSALE_SCREENS,
  ...SUBSCRIPTION_SCREENS,
};
