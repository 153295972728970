import apiClient from './instance';
import { UserData } from './types';

export const createUser = async (payload: UserData): Promise<void> => {
  try {
    const response = await apiClient.post('/v1/users/register', payload);
    console.log('User created successfully:', response.data);
  } catch (error) {
    console.error('Error creating user:', error);
  }
};
