import { ImagesMap } from 'core/interfaces/images';

import MAIL from 'src/shared/images/quiz/male.svg';

import FEMAIL from './femail.svg';

import CARD1_1X from './q1/card.webp';
import CARD1_2X from './q1/card@2x.webp';
import CARD1_3X from './q1/card@3x.webp';

import CARD2_1X from './q2/card.webp';
import CARD2_2X from './q2/card@2x.webp';
import CARD2_3X from './q2/card@3x.webp';

import CARD3_1X from './q3/card.webp';
import CARD3_2X from './q3/card@2x.webp';
import CARD3_3X from './q3/card@3x.webp';

import CARD4_1X from './q4/card.webp';
import CARD4_2X from './q4/card@2x.webp';
import CARD4_3X from './q4/card@3x.webp';

import CARD5_1X from './q5/card.webp';
import CARD5_2X from './q5/card@2x.webp';
import CARD5_3X from './q5/card@3x.webp';

import CARD6_1X from './q6/card.webp';
import CARD6_2X from './q6/card@2x.webp';
import CARD6_3X from './q6/card@3x.webp';

import CARD7_1X from './q7/card.webp';
import CARD7_2X from './q7/card@2x.webp';
import CARD7_3X from './q7/card@3x.webp';

import CARD8_1X from './q8/card.webp';
import CARD8_2X from './q8/card@2x.webp';
import CARD8_3X from './q8/card@3x.webp';

import CARD9_1X from './q9/card.webp';
import CARD9_2X from './q9/card@2x.webp';
import CARD9_3X from './q9/card@3x.webp';

import CARD10_1X from './q10/card.webp';
import CARD10_2X from './q10/card@2x.webp';
import CARD10_3X from './q10/card@3x.webp';

import CARD11_1X from './q11/card.webp';
import CARD11_2X from './q11/card@2x.webp';
import CARD11_3X from './q11/card@3x.webp';

import CARD12_1X from './q12/card.webp';
import CARD12_2X from './q12/card@2x.webp';
import CARD12_3X from './q12/card@3x.webp';

import CARD13_1X from './q13/card.webp';
import CARD13_2X from './q13/card@2x.webp';
import CARD13_3X from './q13/card@3x.webp';

import CARD14_1X from './q14/card.webp';
import CARD14_2X from './q14/card@2x.webp';
import CARD14_3X from './q14/card@3x.webp';

import CARD15_1X from './q15/card.webp';
import CARD15_2X from './q15/card@2x.webp';
import CARD15_3X from './q15/card@3x.webp';

import CARD16_1X from './q16/card.webp';
import CARD16_2X from './q16/card@2x.webp';
import CARD16_3X from './q16/card@3x.webp';

import CARD17_1X from './q17/card.webp';
import CARD17_2X from './q17/card@2x.webp';
import CARD17_3X from './q17/card@3x.webp';

import CARD18_1X from './q18/card.webp';
import CARD18_2X from './q18/card@2x.webp';
import CARD18_3X from './q18/card@3x.webp';

import CARD19_1X from './q19/card.webp';
import CARD19_2X from './q19/card@2x.webp';
import CARD19_3X from './q19/card@3x.webp';

import CARD20_1X from './q20/card.webp';
import CARD20_2X from './q20/card@2x.webp';
import CARD20_3X from './q20/card@3x.webp';

import CARD21_1X from './q21/card.webp';
import CARD21_2X from './q21/card@2x.webp';
import CARD21_3X from './q21/card@3x.webp';

import CARD22_1X from './q22/card.webp';
import CARD22_2X from './q22/card@2x.webp';
import CARD22_3X from './q22/card@3x.webp';

import CARD23_1X from './q23/card.webp';
import CARD23_2X from './q23/card@2x.webp';
import CARD23_3X from './q23/card@3x.webp';

import CARD24_1X from './q24/card.webp';
import CARD24_2X from './q24/card@2x.webp';
import CARD24_3X from './q24/card@3x.webp';

import CARD25_1X from './q25/card.webp';
import CARD25_2X from './q25/card@2x.webp';
import CARD25_3X from './q25/card@3x.webp';

import CARD26_1X from './q26/card.webp';
import CARD26_2X from './q26/card@2x.webp';
import CARD26_3X from './q26/card@3x.webp';

import CARD27_1X from './q27/card.webp';
import CARD27_2X from './q27/card@2x.webp';
import CARD27_3X from './q27/card@3x.webp';

import CARD28_1X from './q28/card.webp';
import CARD28_2X from './q28/card@2x.webp';
import CARD28_3X from './q28/card@3x.webp';

import CARD29_1X from './q29/card.webp';
import CARD29_2X from './q29/card@2x.webp';
import CARD29_3X from './q29/card@3x.webp';

import CARD30_1X from './q30/card.webp';
import CARD30_2X from './q30/card@2x.webp';
import CARD30_3X from './q30/card@3x.webp';

//

import ANSWER1_1_1X from './q1/a1.webp';
import ANSWER1_1_2X from './q1/a1@2x.webp';
import ANSWER1_1_3X from './q1/a1@3x.webp';

import ANSWER1_2_1X from './q1/a2.webp';
import ANSWER1_2_2X from './q1/a2@2x.webp';
import ANSWER1_2_3X from './q1/a2@3x.webp';

import ANSWER1_3_1X from './q1/a3.webp';
import ANSWER1_3_2X from './q1/a3@2x.webp';
import ANSWER1_3_3X from './q1/a3@3x.webp';

import ANSWER1_4_1X from './q1/a4.webp';
import ANSWER1_4_2X from './q1/a4@2x.webp';
import ANSWER1_4_3X from './q1/a4@3x.webp';

import ANSWER1_5_1X from './q1/a5.webp';
import ANSWER1_5_2X from './q1/a5@2x.webp';
import ANSWER1_5_3X from './q1/a5@3x.webp';

import ANSWER1_6_1X from './q1/a6.webp';
import ANSWER1_6_2X from './q1/a6@2x.webp';
import ANSWER1_6_3X from './q1/a6@3x.webp';

// 

import ANSWER2_1_1X from './q2/a1.webp';
import ANSWER2_1_2X from './q2/a1@2x.webp';
import ANSWER2_1_3X from './q2/a1@3x.webp';

import ANSWER2_2_1X from './q2/a2.webp';
import ANSWER2_2_2X from './q2/a2@2x.webp';
import ANSWER2_2_3X from './q2/a2@3x.webp';

import ANSWER2_3_1X from './q2/a3.webp';
import ANSWER2_3_2X from './q2/a3@2x.webp';
import ANSWER2_3_3X from './q2/a3@3x.webp';

import ANSWER2_4_1X from './q2/a4.webp';
import ANSWER2_4_2X from './q2/a4@2x.webp';
import ANSWER2_4_3X from './q2/a4@3x.webp';

import ANSWER2_5_1X from './q2/a5.webp';
import ANSWER2_5_2X from './q2/a5@2x.webp';
import ANSWER2_5_3X from './q2/a5@3x.webp';

import ANSWER2_6_1X from './q2/a6.webp';
import ANSWER2_6_2X from './q2/a6@2x.webp';
import ANSWER2_6_3X from './q2/a6@3x.webp';

// 

import ANSWER3_1_1X from './q3/a1.webp';
import ANSWER3_1_2X from './q3/a1@2x.webp';
import ANSWER3_1_3X from './q3/a1@3x.webp';

import ANSWER3_2_1X from './q3/a2.webp';
import ANSWER3_2_2X from './q3/a2@2x.webp';
import ANSWER3_2_3X from './q3/a2@3x.webp';

import ANSWER3_3_1X from './q3/a3.webp';
import ANSWER3_3_2X from './q3/a3@2x.webp';
import ANSWER3_3_3X from './q3/a3@3x.webp';

import ANSWER3_4_1X from './q3/a4.webp';
import ANSWER3_4_2X from './q3/a4@2x.webp';
import ANSWER3_4_3X from './q3/a4@3x.webp';

import ANSWER3_5_1X from './q3/a5.webp';
import ANSWER3_5_2X from './q3/a5@2x.webp';
import ANSWER3_5_3X from './q3/a5@3x.webp';

import ANSWER3_6_1X from './q3/a6.webp';
import ANSWER3_6_2X from './q3/a6@2x.webp';
import ANSWER3_6_3X from './q3/a6@3x.webp';

// 

import ANSWER4_1_1X from './q4/a1.webp';
import ANSWER4_1_2X from './q4/a1@2x.webp';
import ANSWER4_1_3X from './q4/a1@3x.webp';

import ANSWER4_2_1X from './q4/a2.webp';
import ANSWER4_2_2X from './q4/a2@2x.webp';
import ANSWER4_2_3X from './q4/a2@3x.webp';

import ANSWER4_3_1X from './q4/a3.webp';
import ANSWER4_3_2X from './q4/a3@2x.webp';
import ANSWER4_3_3X from './q4/a3@3x.webp';

import ANSWER4_4_1X from './q4/a4.webp';
import ANSWER4_4_2X from './q4/a4@2x.webp';
import ANSWER4_4_3X from './q4/a4@3x.webp';

import ANSWER4_5_1X from './q4/a5.webp';
import ANSWER4_5_2X from './q4/a5@2x.webp';
import ANSWER4_5_3X from './q4/a5@3x.webp';

import ANSWER4_6_1X from './q4/a6.webp';
import ANSWER4_6_2X from './q4/a6@2x.webp';
import ANSWER4_6_3X from './q4/a6@3x.webp';

// 

import ANSWER5_1_1X from './q5/a1.webp';
import ANSWER5_1_2X from './q5/a1@2x.webp';
import ANSWER5_1_3X from './q5/a1@3x.webp';

import ANSWER5_2_1X from './q5/a2.webp';
import ANSWER5_2_2X from './q5/a2@2x.webp';
import ANSWER5_2_3X from './q5/a2@3x.webp';

import ANSWER5_3_1X from './q5/a3.webp';
import ANSWER5_3_2X from './q5/a3@2x.webp';
import ANSWER5_3_3X from './q5/a3@3x.webp';

import ANSWER5_4_1X from './q5/a4.webp';
import ANSWER5_4_2X from './q5/a4@2x.webp';
import ANSWER5_4_3X from './q5/a4@3x.webp';

import ANSWER5_5_1X from './q5/a5.webp';
import ANSWER5_5_2X from './q5/a5@2x.webp';
import ANSWER5_5_3X from './q5/a5@3x.webp';

import ANSWER5_6_1X from './q5/a6.webp';
import ANSWER5_6_2X from './q5/a6@2x.webp';
import ANSWER5_6_3X from './q5/a6@3x.webp';

// 

import ANSWER6_1_1X from './q6/a1.webp';
import ANSWER6_1_2X from './q6/a1@2x.webp';
import ANSWER6_1_3X from './q6/a1@3x.webp';

import ANSWER6_2_1X from './q6/a2.webp';
import ANSWER6_2_2X from './q6/a2@2x.webp';
import ANSWER6_2_3X from './q6/a2@3x.webp';

import ANSWER6_3_1X from './q6/a3.webp';
import ANSWER6_3_2X from './q6/a3@2x.webp';
import ANSWER6_3_3X from './q6/a3@3x.webp';

import ANSWER6_4_1X from './q6/a4.webp';
import ANSWER6_4_2X from './q6/a4@2x.webp';
import ANSWER6_4_3X from './q6/a4@3x.webp';

import ANSWER6_5_1X from './q6/a5.webp';
import ANSWER6_5_2X from './q6/a5@2x.webp';
import ANSWER6_5_3X from './q6/a5@3x.webp';

import ANSWER6_6_1X from './q6/a6.webp';
import ANSWER6_6_2X from './q6/a6@2x.webp';
import ANSWER6_6_3X from './q6/a6@3x.webp';

// 

import ANSWER7_1_1X from './q7/a1.webp';
import ANSWER7_1_2X from './q7/a1@2x.webp';
import ANSWER7_1_3X from './q7/a1@3x.webp';

import ANSWER7_2_1X from './q7/a2.webp';
import ANSWER7_2_2X from './q7/a2@2x.webp';
import ANSWER7_2_3X from './q7/a2@3x.webp';

import ANSWER7_3_1X from './q7/a3.webp';
import ANSWER7_3_2X from './q7/a3@2x.webp';
import ANSWER7_3_3X from './q7/a3@3x.webp';

import ANSWER7_4_1X from './q7/a4.webp';
import ANSWER7_4_2X from './q7/a4@2x.webp';
import ANSWER7_4_3X from './q7/a4@3x.webp';

import ANSWER7_5_1X from './q7/a5.webp';
import ANSWER7_5_2X from './q7/a5@2x.webp';
import ANSWER7_5_3X from './q7/a5@3x.webp';

import ANSWER7_6_1X from './q7/a6.webp';
import ANSWER7_6_2X from './q7/a6@2x.webp';
import ANSWER7_6_3X from './q7/a6@3x.webp';

// 

import ANSWER8_1_1X from './q8/a1.webp';
import ANSWER8_1_2X from './q8/a1@2x.webp';
import ANSWER8_1_3X from './q8/a1@3x.webp';

import ANSWER8_2_1X from './q8/a2.webp';
import ANSWER8_2_2X from './q8/a2@2x.webp';
import ANSWER8_2_3X from './q8/a2@3x.webp';

import ANSWER8_3_1X from './q8/a3.webp';
import ANSWER8_3_2X from './q8/a3@2x.webp';
import ANSWER8_3_3X from './q8/a3@3x.webp';

import ANSWER8_4_1X from './q8/a4.webp';
import ANSWER8_4_2X from './q8/a4@2x.webp';
import ANSWER8_4_3X from './q8/a4@3x.webp';

import ANSWER8_5_1X from './q8/a5.webp';
import ANSWER8_5_2X from './q8/a5@2x.webp';
import ANSWER8_5_3X from './q8/a5@3x.webp';

import ANSWER8_6_1X from './q8/a6.webp';
import ANSWER8_6_2X from './q8/a6@2x.webp';
import ANSWER8_6_3X from './q8/a6@3x.webp';

// 

import ANSWER9_1_1X from './q9/a1.webp';
import ANSWER9_1_2X from './q9/a1@2x.webp';
import ANSWER9_1_3X from './q9/a1@3x.webp';

import ANSWER9_2_1X from './q9/a2.webp';
import ANSWER9_2_2X from './q9/a2@2x.webp';
import ANSWER9_2_3X from './q9/a2@3x.webp';

import ANSWER9_3_1X from './q9/a3.webp';
import ANSWER9_3_2X from './q9/a3@2x.webp';
import ANSWER9_3_3X from './q9/a3@3x.webp';

import ANSWER9_4_1X from './q9/a4.webp';
import ANSWER9_4_2X from './q9/a4@2x.webp';
import ANSWER9_4_3X from './q9/a4@3x.webp';

import ANSWER9_5_1X from './q9/a5.webp';
import ANSWER9_5_2X from './q9/a5@2x.webp';
import ANSWER9_5_3X from './q9/a5@3x.webp';

import ANSWER9_6_1X from './q9/a6.webp';
import ANSWER9_6_2X from './q9/a6@2x.webp';
import ANSWER9_6_3X from './q9/a6@3x.webp';

// 

import ANSWER10_1_1X from './q10/a1.webp';
import ANSWER10_1_2X from './q10/a1@2x.webp';
import ANSWER10_1_3X from './q10/a1@3x.webp';

import ANSWER10_2_1X from './q10/a2.webp';
import ANSWER10_2_2X from './q10/a2@2x.webp';
import ANSWER10_2_3X from './q10/a2@3x.webp';

import ANSWER10_3_1X from './q10/a3.webp';
import ANSWER10_3_2X from './q10/a3@2x.webp';
import ANSWER10_3_3X from './q10/a3@3x.webp';

import ANSWER10_4_1X from './q10/a4.webp';
import ANSWER10_4_2X from './q10/a4@2x.webp';
import ANSWER10_4_3X from './q10/a4@3x.webp';

import ANSWER10_5_1X from './q10/a5.webp';
import ANSWER10_5_2X from './q10/a5@2x.webp';
import ANSWER10_5_3X from './q10/a5@3x.webp';

import ANSWER10_6_1X from './q10/a6.webp';
import ANSWER10_6_2X from './q10/a6@2x.webp';
import ANSWER10_6_3X from './q10/a6@3x.webp';

///////

import ANSWER11_1_1X from './q11/a1.webp';
import ANSWER11_1_2X from './q11/a1@2x.webp';
import ANSWER11_1_3X from './q11/a1@3x.webp';

import ANSWER11_2_1X from './q11/a2.webp';
import ANSWER11_2_2X from './q11/a2@2x.webp';
import ANSWER11_2_3X from './q11/a2@3x.webp';

import ANSWER11_3_1X from './q11/a3.webp';
import ANSWER11_3_2X from './q11/a3@2x.webp';
import ANSWER11_3_3X from './q11/a3@3x.webp';

import ANSWER11_4_1X from './q11/a4.webp';
import ANSWER11_4_2X from './q11/a4@2x.webp';
import ANSWER11_4_3X from './q11/a4@3x.webp';

import ANSWER11_5_1X from './q11/a5.webp';
import ANSWER11_5_2X from './q11/a5@2x.webp';
import ANSWER11_5_3X from './q11/a5@3x.webp';

import ANSWER11_6_1X from './q11/a6.webp';
import ANSWER11_6_2X from './q11/a6@2x.webp';
import ANSWER11_6_3X from './q11/a6@3x.webp';

// 

import ANSWER12_1_1X from './q12/a1.webp';
import ANSWER12_1_2X from './q12/a1@2x.webp';
import ANSWER12_1_3X from './q12/a1@3x.webp';

import ANSWER12_2_1X from './q12/a2.webp';
import ANSWER12_2_2X from './q12/a2@2x.webp';
import ANSWER12_2_3X from './q12/a2@3x.webp';

import ANSWER12_3_1X from './q12/a3.webp';
import ANSWER12_3_2X from './q12/a3@2x.webp';
import ANSWER12_3_3X from './q12/a3@3x.webp';

import ANSWER12_4_1X from './q12/a4.webp';
import ANSWER12_4_2X from './q12/a4@2x.webp';
import ANSWER12_4_3X from './q12/a4@3x.webp';

import ANSWER12_5_1X from './q12/a5.webp';
import ANSWER12_5_2X from './q12/a5@2x.webp';
import ANSWER12_5_3X from './q12/a5@3x.webp';

import ANSWER12_6_1X from './q12/a6.webp';
import ANSWER12_6_2X from './q12/a6@2x.webp';
import ANSWER12_6_3X from './q12/a6@3x.webp';

// 

import ANSWER13_1_1X from './q13/a1.webp';
import ANSWER13_1_2X from './q13/a1@2x.webp';
import ANSWER13_1_3X from './q13/a1@3x.webp';

import ANSWER13_2_1X from './q13/a2.webp';
import ANSWER13_2_2X from './q13/a2@2x.webp';
import ANSWER13_2_3X from './q13/a2@3x.webp';

import ANSWER13_3_1X from './q13/a3.webp';
import ANSWER13_3_2X from './q13/a3@2x.webp';
import ANSWER13_3_3X from './q13/a3@3x.webp';

import ANSWER13_4_1X from './q13/a4.webp';
import ANSWER13_4_2X from './q13/a4@2x.webp';
import ANSWER13_4_3X from './q13/a4@3x.webp';

import ANSWER13_5_1X from './q13/a5.webp';
import ANSWER13_5_2X from './q13/a5@2x.webp';
import ANSWER13_5_3X from './q13/a5@3x.webp';

import ANSWER13_6_1X from './q13/a6.webp';
import ANSWER13_6_2X from './q13/a6@2x.webp';
import ANSWER13_6_3X from './q13/a6@3x.webp';

// 

import ANSWER14_1_1X from './q14/a1.webp';
import ANSWER14_1_2X from './q14/a1@2x.webp';
import ANSWER14_1_3X from './q14/a1@3x.webp';

import ANSWER14_2_1X from './q14/a2.webp';
import ANSWER14_2_2X from './q14/a2@2x.webp';
import ANSWER14_2_3X from './q14/a2@3x.webp';

import ANSWER14_3_1X from './q14/a3.webp';
import ANSWER14_3_2X from './q14/a3@2x.webp';
import ANSWER14_3_3X from './q14/a3@3x.webp';

import ANSWER14_4_1X from './q14/a4.webp';
import ANSWER14_4_2X from './q14/a4@2x.webp';
import ANSWER14_4_3X from './q14/a4@3x.webp';

import ANSWER14_5_1X from './q14/a5.webp';
import ANSWER14_5_2X from './q14/a5@2x.webp';
import ANSWER14_5_3X from './q14/a5@3x.webp';

import ANSWER14_6_1X from './q14/a6.webp';
import ANSWER14_6_2X from './q14/a6@2x.webp';
import ANSWER14_6_3X from './q14/a6@3x.webp';

// 

import ANSWER15_1_1X from './q15/a1.webp';
import ANSWER15_1_2X from './q15/a1@2x.webp';
import ANSWER15_1_3X from './q15/a1@3x.webp';

import ANSWER15_2_1X from './q15/a2.webp';
import ANSWER15_2_2X from './q15/a2@2x.webp';
import ANSWER15_2_3X from './q15/a2@3x.webp';

import ANSWER15_3_1X from './q15/a3.webp';
import ANSWER15_3_2X from './q15/a3@2x.webp';
import ANSWER15_3_3X from './q15/a3@3x.webp';

import ANSWER15_4_1X from './q15/a4.webp';
import ANSWER15_4_2X from './q15/a4@2x.webp';
import ANSWER15_4_3X from './q15/a4@3x.webp';

import ANSWER15_5_1X from './q15/a5.webp';
import ANSWER15_5_2X from './q15/a5@2x.webp';
import ANSWER15_5_3X from './q15/a5@3x.webp';

import ANSWER15_6_1X from './q15/a6.webp';
import ANSWER15_6_2X from './q15/a6@2x.webp';
import ANSWER15_6_3X from './q15/a6@3x.webp';

// 

import ANSWER16_1_1X from './q16/a1.webp';
import ANSWER16_1_2X from './q16/a1@2x.webp';
import ANSWER16_1_3X from './q16/a1@3x.webp';

import ANSWER16_2_1X from './q16/a2.webp';
import ANSWER16_2_2X from './q16/a2@2x.webp';
import ANSWER16_2_3X from './q16/a2@3x.webp';

import ANSWER16_3_1X from './q16/a3.webp';
import ANSWER16_3_2X from './q16/a3@2x.webp';
import ANSWER16_3_3X from './q16/a3@3x.webp';

import ANSWER16_4_1X from './q16/a4.webp';
import ANSWER16_4_2X from './q16/a4@2x.webp';
import ANSWER16_4_3X from './q16/a4@3x.webp';

import ANSWER16_5_1X from './q16/a5.webp';
import ANSWER16_5_2X from './q16/a5@2x.webp';
import ANSWER16_5_3X from './q16/a5@3x.webp';

import ANSWER16_6_1X from './q16/a6.webp';
import ANSWER16_6_2X from './q16/a6@2x.webp';
import ANSWER16_6_3X from './q16/a6@3x.webp';

// 

import ANSWER17_1_1X from './q17/a1.webp';
import ANSWER17_1_2X from './q17/a1@2x.webp';
import ANSWER17_1_3X from './q17/a1@3x.webp';

import ANSWER17_2_1X from './q17/a2.webp';
import ANSWER17_2_2X from './q17/a2@2x.webp';
import ANSWER17_2_3X from './q17/a2@3x.webp';

import ANSWER17_3_1X from './q17/a3.webp';
import ANSWER17_3_2X from './q17/a3@2x.webp';
import ANSWER17_3_3X from './q17/a3@3x.webp';

import ANSWER17_4_1X from './q17/a4.webp';
import ANSWER17_4_2X from './q17/a4@2x.webp';
import ANSWER17_4_3X from './q17/a4@3x.webp';

import ANSWER17_5_1X from './q17/a5.webp';
import ANSWER17_5_2X from './q17/a5@2x.webp';
import ANSWER17_5_3X from './q17/a5@3x.webp';

import ANSWER17_6_1X from './q17/a6.webp';
import ANSWER17_6_2X from './q17/a6@2x.webp';
import ANSWER17_6_3X from './q17/a6@3x.webp';

// 

import ANSWER18_1_1X from './q18/a1.webp';
import ANSWER18_1_2X from './q18/a1@2x.webp';
import ANSWER18_1_3X from './q18/a1@3x.webp';

import ANSWER18_2_1X from './q18/a2.webp';
import ANSWER18_2_2X from './q18/a2@2x.webp';
import ANSWER18_2_3X from './q18/a2@3x.webp';

import ANSWER18_3_1X from './q18/a3.webp';
import ANSWER18_3_2X from './q18/a3@2x.webp';
import ANSWER18_3_3X from './q18/a3@3x.webp';

import ANSWER18_4_1X from './q18/a4.webp';
import ANSWER18_4_2X from './q18/a4@2x.webp';
import ANSWER18_4_3X from './q18/a4@3x.webp';

import ANSWER18_5_1X from './q18/a5.webp';
import ANSWER18_5_2X from './q18/a5@2x.webp';
import ANSWER18_5_3X from './q18/a5@3x.webp';

import ANSWER18_6_1X from './q18/a6.webp';
import ANSWER18_6_2X from './q18/a6@2x.webp';
import ANSWER18_6_3X from './q18/a6@3x.webp';

// 

import ANSWER19_1_1X from './q19/a1.webp';
import ANSWER19_1_2X from './q19/a1@2x.webp';
import ANSWER19_1_3X from './q19/a1@3x.webp';

import ANSWER19_2_1X from './q19/a2.webp';
import ANSWER19_2_2X from './q19/a2@2x.webp';
import ANSWER19_2_3X from './q19/a2@3x.webp';

import ANSWER19_3_1X from './q19/a3.webp';
import ANSWER19_3_2X from './q19/a3@2x.webp';
import ANSWER19_3_3X from './q19/a3@3x.webp';

import ANSWER19_4_1X from './q19/a4.webp';
import ANSWER19_4_2X from './q19/a4@2x.webp';
import ANSWER19_4_3X from './q19/a4@3x.webp';

import ANSWER19_5_1X from './q19/a5.webp';
import ANSWER19_5_2X from './q19/a5@2x.webp';
import ANSWER19_5_3X from './q19/a5@3x.webp';

import ANSWER19_6_1X from './q19/a6.webp';
import ANSWER19_6_2X from './q19/a6@2x.webp';
import ANSWER19_6_3X from './q19/a6@3x.webp';

// 

import ANSWER20_1_1X from './q20/a1.webp';
import ANSWER20_1_2X from './q20/a1@2x.webp';
import ANSWER20_1_3X from './q20/a1@3x.webp';

import ANSWER20_2_1X from './q20/a2.webp';
import ANSWER20_2_2X from './q20/a2@2x.webp';
import ANSWER20_2_3X from './q20/a2@3x.webp';

import ANSWER20_3_1X from './q20/a3.webp';
import ANSWER20_3_2X from './q20/a3@2x.webp';
import ANSWER20_3_3X from './q20/a3@3x.webp';

import ANSWER20_4_1X from './q20/a4.webp';
import ANSWER20_4_2X from './q20/a4@2x.webp';
import ANSWER20_4_3X from './q20/a4@3x.webp';

import ANSWER20_5_1X from './q20/a5.webp';
import ANSWER20_5_2X from './q20/a5@2x.webp';
import ANSWER20_5_3X from './q20/a5@3x.webp';

import ANSWER20_6_1X from './q20/a6.webp';
import ANSWER20_6_2X from './q20/a6@2x.webp';
import ANSWER20_6_3X from './q20/a6@3x.webp';


/////

import ANSWER21_1_1X from './q21/a1.webp';
import ANSWER21_1_2X from './q21/a1@2x.webp';
import ANSWER21_1_3X from './q21/a1@3x.webp';

import ANSWER21_2_1X from './q21/a2.webp';
import ANSWER21_2_2X from './q21/a2@2x.webp';
import ANSWER21_2_3X from './q21/a2@3x.webp';

import ANSWER21_3_1X from './q21/a3.webp';
import ANSWER21_3_2X from './q21/a3@2x.webp';
import ANSWER21_3_3X from './q21/a3@3x.webp';

import ANSWER21_4_1X from './q21/a4.webp';
import ANSWER21_4_2X from './q21/a4@2x.webp';
import ANSWER21_4_3X from './q21/a4@3x.webp';

import ANSWER21_5_1X from './q21/a5.webp';
import ANSWER21_5_2X from './q21/a5@2x.webp';
import ANSWER21_5_3X from './q21/a5@3x.webp';

import ANSWER21_6_1X from './q21/a6.webp';
import ANSWER21_6_2X from './q21/a6@2x.webp';
import ANSWER21_6_3X from './q21/a6@3x.webp';

// 

import ANSWER22_1_1X from './q22/a1.webp';
import ANSWER22_1_2X from './q22/a1@2x.webp';
import ANSWER22_1_3X from './q22/a1@3x.webp';

import ANSWER22_2_1X from './q22/a2.webp';
import ANSWER22_2_2X from './q22/a2@2x.webp';
import ANSWER22_2_3X from './q22/a2@3x.webp';

import ANSWER22_3_1X from './q22/a3.webp';
import ANSWER22_3_2X from './q22/a3@2x.webp';
import ANSWER22_3_3X from './q22/a3@3x.webp';

import ANSWER22_4_1X from './q22/a4.webp';
import ANSWER22_4_2X from './q22/a4@2x.webp';
import ANSWER22_4_3X from './q22/a4@3x.webp';

import ANSWER22_5_1X from './q22/a5.webp';
import ANSWER22_5_2X from './q22/a5@2x.webp';
import ANSWER22_5_3X from './q22/a5@3x.webp';

import ANSWER22_6_1X from './q22/a6.webp';
import ANSWER22_6_2X from './q22/a6@2x.webp';
import ANSWER22_6_3X from './q22/a6@3x.webp';

// 

import ANSWER23_1_1X from './q23/a1.webp';
import ANSWER23_1_2X from './q23/a1@2x.webp';
import ANSWER23_1_3X from './q23/a1@3x.webp';

import ANSWER23_2_1X from './q23/a2.webp';
import ANSWER23_2_2X from './q23/a2@2x.webp';
import ANSWER23_2_3X from './q23/a2@3x.webp';

import ANSWER23_3_1X from './q23/a3.webp';
import ANSWER23_3_2X from './q23/a3@2x.webp';
import ANSWER23_3_3X from './q23/a3@3x.webp';

import ANSWER23_4_1X from './q23/a4.webp';
import ANSWER23_4_2X from './q23/a4@2x.webp';
import ANSWER23_4_3X from './q23/a4@3x.webp';

import ANSWER23_5_1X from './q23/a5.webp';
import ANSWER23_5_2X from './q23/a5@2x.webp';
import ANSWER23_5_3X from './q23/a5@3x.webp';

import ANSWER23_6_1X from './q23/a6.webp';
import ANSWER23_6_2X from './q23/a6@2x.webp';
import ANSWER23_6_3X from './q23/a6@3x.webp';

// 

import ANSWER24_1_1X from './q24/a1.webp';
import ANSWER24_1_2X from './q24/a1@2x.webp';
import ANSWER24_1_3X from './q24/a1@3x.webp';

import ANSWER24_2_1X from './q24/a2.webp';
import ANSWER24_2_2X from './q24/a2@2x.webp';
import ANSWER24_2_3X from './q24/a2@3x.webp';

import ANSWER24_3_1X from './q24/a3.webp';
import ANSWER24_3_2X from './q24/a3@2x.webp';
import ANSWER24_3_3X from './q24/a3@3x.webp';

import ANSWER24_4_1X from './q24/a4.webp';
import ANSWER24_4_2X from './q24/a4@2x.webp';
import ANSWER24_4_3X from './q24/a4@3x.webp';

import ANSWER24_5_1X from './q24/a5.webp';
import ANSWER24_5_2X from './q24/a5@2x.webp';
import ANSWER24_5_3X from './q24/a5@3x.webp';

import ANSWER24_6_1X from './q24/a6.webp';
import ANSWER24_6_2X from './q24/a6@2x.webp';
import ANSWER24_6_3X from './q24/a6@3x.webp';

// 

import ANSWER25_1_1X from './q25/a1.webp';
import ANSWER25_1_2X from './q25/a1@2x.webp';
import ANSWER25_1_3X from './q25/a1@3x.webp';

import ANSWER25_2_1X from './q25/a2.webp';
import ANSWER25_2_2X from './q25/a2@2x.webp';
import ANSWER25_2_3X from './q25/a2@3x.webp';

import ANSWER25_3_1X from './q25/a3.webp';
import ANSWER25_3_2X from './q25/a3@2x.webp';
import ANSWER25_3_3X from './q25/a3@3x.webp';

import ANSWER25_4_1X from './q25/a4.webp';
import ANSWER25_4_2X from './q25/a4@2x.webp';
import ANSWER25_4_3X from './q25/a4@3x.webp';

import ANSWER25_5_1X from './q25/a5.webp';
import ANSWER25_5_2X from './q25/a5@2x.webp';
import ANSWER25_5_3X from './q25/a5@3x.webp';

import ANSWER25_6_1X from './q25/a6.webp';
import ANSWER25_6_2X from './q25/a6@2x.webp';
import ANSWER25_6_3X from './q25/a6@3x.webp';

// 

import ANSWER26_1_1X from './q26/a1.webp';
import ANSWER26_1_2X from './q26/a1@2x.webp';
import ANSWER26_1_3X from './q26/a1@3x.webp';

import ANSWER26_2_1X from './q26/a2.webp';
import ANSWER26_2_2X from './q26/a2@2x.webp';
import ANSWER26_2_3X from './q26/a2@3x.webp';

import ANSWER26_3_1X from './q26/a3.webp';
import ANSWER26_3_2X from './q26/a3@2x.webp';
import ANSWER26_3_3X from './q26/a3@3x.webp';

import ANSWER26_4_1X from './q26/a4.webp';
import ANSWER26_4_2X from './q26/a4@2x.webp';
import ANSWER26_4_3X from './q26/a4@3x.webp';

import ANSWER26_5_1X from './q26/a5.webp';
import ANSWER26_5_2X from './q26/a5@2x.webp';
import ANSWER26_5_3X from './q26/a5@3x.webp';

import ANSWER26_6_1X from './q26/a6.webp';
import ANSWER26_6_2X from './q26/a6@2x.webp';
import ANSWER26_6_3X from './q26/a6@3x.webp';

// 

import ANSWER27_1_1X from './q27/a1.webp';
import ANSWER27_1_2X from './q27/a1@2x.webp';
import ANSWER27_1_3X from './q27/a1@3x.webp';

import ANSWER27_2_1X from './q27/a2.webp';
import ANSWER27_2_2X from './q27/a2@2x.webp';
import ANSWER27_2_3X from './q27/a2@3x.webp';

import ANSWER27_3_1X from './q27/a3.webp';
import ANSWER27_3_2X from './q27/a3@2x.webp';
import ANSWER27_3_3X from './q27/a3@3x.webp';

import ANSWER27_4_1X from './q27/a4.webp';
import ANSWER27_4_2X from './q27/a4@2x.webp';
import ANSWER27_4_3X from './q27/a4@3x.webp';

import ANSWER27_5_1X from './q27/a5.webp';
import ANSWER27_5_2X from './q27/a5@2x.webp';
import ANSWER27_5_3X from './q27/a5@3x.webp';

import ANSWER27_6_1X from './q27/a6.webp';
import ANSWER27_6_2X from './q27/a6@2x.webp';
import ANSWER27_6_3X from './q27/a6@3x.webp';

// 

import ANSWER28_1_1X from './q28/a1.webp';
import ANSWER28_1_2X from './q28/a1@2x.webp';
import ANSWER28_1_3X from './q28/a1@3x.webp';

import ANSWER28_2_1X from './q28/a2.webp';
import ANSWER28_2_2X from './q28/a2@2x.webp';
import ANSWER28_2_3X from './q28/a2@3x.webp';

import ANSWER28_3_1X from './q28/a3.webp';
import ANSWER28_3_2X from './q28/a3@2x.webp';
import ANSWER28_3_3X from './q8/a3@3x.webp';

import ANSWER28_4_1X from './q28/a4.webp';
import ANSWER28_4_2X from './q28/a4@2x.webp';
import ANSWER28_4_3X from './q28/a4@3x.webp';

import ANSWER28_5_1X from './q28/a5.webp';
import ANSWER28_5_2X from './q28/a5@2x.webp';
import ANSWER28_5_3X from './q28/a5@3x.webp';

import ANSWER28_6_1X from './q28/a6.webp';
import ANSWER28_6_2X from './q28/a6@2x.webp';
import ANSWER28_6_3X from './q28/a6@3x.webp';

// 

import ANSWER29_1_1X from './q29/a1.webp';
import ANSWER29_1_2X from './q29/a1@2x.webp';
import ANSWER29_1_3X from './q29/a1@3x.webp';

import ANSWER29_2_1X from './q29/a2.webp';
import ANSWER29_2_2X from './q29/a2@2x.webp';
import ANSWER29_2_3X from './q29/a2@3x.webp';

import ANSWER29_3_1X from './q29/a3.webp';
import ANSWER29_3_2X from './q29/a3@2x.webp';
import ANSWER29_3_3X from './q29/a3@3x.webp';

import ANSWER29_4_1X from './q29/a4.webp';
import ANSWER29_4_2X from './q29/a4@2x.webp';
import ANSWER29_4_3X from './q29/a4@3x.webp';

import ANSWER29_5_1X from './q29/a5.webp';
import ANSWER29_5_2X from './q29/a5@2x.webp';
import ANSWER29_5_3X from './q29/a5@3x.webp';

import ANSWER29_6_1X from './q29/a6.webp';
import ANSWER29_6_2X from './q29/a6@2x.webp';
import ANSWER29_6_3X from './q29/a6@3x.webp';

// 

import ANSWER30_1_1X from './q30/a1.webp';
import ANSWER30_1_2X from './q30/a1@2x.webp';
import ANSWER30_1_3X from './q30/a1@3x.webp';

import ANSWER30_2_1X from './q30/a2.webp';
import ANSWER30_2_2X from './q30/a2@2x.webp';
import ANSWER30_2_3X from './q30/a2@3x.webp';

import ANSWER30_3_1X from './q30/a3.webp';
import ANSWER30_3_2X from './q30/a3@2x.webp';
import ANSWER30_3_3X from './q30/a3@3x.webp';

import ANSWER30_4_1X from './q30/a4.webp';
import ANSWER30_4_2X from './q30/a4@2x.webp';
import ANSWER30_4_3X from './q30/a4@3x.webp';

import ANSWER30_5_1X from './q30/a5.webp';
import ANSWER30_5_2X from './q30/a5@2x.webp';
import ANSWER30_5_3X from './q30/a5@3x.webp';

import ANSWER30_6_1X from './q30/a6.webp';
import ANSWER30_6_2X from './q30/a6@2x.webp';
import ANSWER30_6_3X from './q30/a6@3x.webp';


export const IMAGES: ImagesMap = {
  FEMALE: {
    src: FEMAIL,
  },
  MALE: {
    src: MAIL,
  },
  CARD1: {
    src: CARD1_1X,
    srcSet: `${CARD1_1X}, ${CARD1_2X} 2x, ${CARD1_3X} 3x`
  },
  CARD2: {
    src: CARD2_1X,
    srcSet: `${CARD2_1X}, ${CARD2_2X} 2x, ${CARD2_3X} 3x`
  },
  CARD3: {
    src: CARD3_1X,
    srcSet: `${CARD3_1X}, ${CARD3_2X} 2x, ${CARD3_3X} 3x`
  },
  CARD4: {
    src: CARD4_1X,
    srcSet: `${CARD4_1X}, ${CARD4_2X} 2x, ${CARD4_3X} 3x`
  },
  CARD5: {
    src: CARD5_1X,
    srcSet: `${CARD5_1X}, ${CARD5_2X} 2x, ${CARD5_3X} 3x`
  },
  CARD6: {
    src: CARD6_1X,
    srcSet: `${CARD6_1X}, ${CARD6_2X} 2x, ${CARD6_3X} 3x`
  },
  CARD7: {
    src: CARD7_1X,
    srcSet: `${CARD7_1X}, ${CARD7_2X} 2x, ${CARD7_3X} 3x`
  },
  CARD8: {
    src: CARD8_1X,
    srcSet: `${CARD8_1X}, ${CARD8_2X} 2x, ${CARD8_3X} 3x`
  },
  CARD9: {
    src: CARD9_1X,
    srcSet: `${CARD9_1X}, ${CARD9_2X} 2x, ${CARD9_3X} 3x`
  },
  CARD10: {
    src: CARD10_1X,
    srcSet: `${CARD10_1X}, ${CARD10_2X} 2x, ${CARD10_3X} 3x`
  },
  CARD11: {
    src: CARD11_1X,
    srcSet: `${CARD11_1X}, ${CARD11_2X} 2x, ${CARD11_3X} 3x`
  },
  CARD12: {
    src: CARD12_1X,
    srcSet: `${CARD12_1X}, ${CARD12_2X} 2x, ${CARD12_3X} 3x`
  },
  CARD13: {
    src: CARD13_1X,
    srcSet: `${CARD13_1X}, ${CARD13_2X} 2x, ${CARD13_3X} 3x`
  },
  CARD14: {
    src: CARD14_1X,
    srcSet: `${CARD14_1X}, ${CARD14_2X} 2x, ${CARD14_3X} 3x`
  },
  CARD15: {
    src: CARD15_1X,
    srcSet: `${CARD15_1X}, ${CARD15_2X} 2x, ${CARD15_3X} 3x`
  },
  CARD16: {
    src: CARD16_1X,
    srcSet: `${CARD16_1X}, ${CARD16_2X} 2x, ${CARD16_3X} 3x`
  },
  CARD17: {
    src: CARD17_1X,
    srcSet: `${CARD17_1X}, ${CARD17_2X} 2x, ${CARD17_3X} 3x`
  },
  CARD18: {
    src: CARD18_1X,
    srcSet: `${CARD18_1X}, ${CARD18_2X} 2x, ${CARD18_3X} 3x`
  },
  CARD19: {
    src: CARD19_1X,
    srcSet: `${CARD19_1X}, ${CARD19_2X} 2x, ${CARD19_3X} 3x`
  },
  CARD20: {
    src: CARD20_1X,
    srcSet: `${CARD20_1X}, ${CARD20_2X} 2x, ${CARD20_3X} 3x`
  },
  CARD21: {
    src: CARD21_1X,
    srcSet: `${CARD21_1X}, ${CARD21_2X} 2x, ${CARD21_3X} 3x`
  },
  CARD22: {
    src: CARD22_1X,
    srcSet: `${CARD22_1X}, ${CARD22_2X} 2x, ${CARD22_3X} 3x`
  },
  CARD23: {
    src: CARD23_1X,
    srcSet: `${CARD23_1X}, ${CARD23_2X} 2x, ${CARD23_3X} 3x`
  },
  CARD24: {
    src: CARD24_1X,
    srcSet: `${CARD24_1X}, ${CARD24_2X} 2x, ${CARD24_3X} 3x`
  },
  CARD25: {
    src: CARD25_1X,
    srcSet: `${CARD25_1X}, ${CARD25_2X} 2x, ${CARD25_3X} 3x`
  },
  CARD26: {
    src: CARD26_1X,
    srcSet: `${CARD26_1X}, ${CARD26_2X} 2x, ${CARD26_3X} 3x`
  },
  CARD27: {
    src: CARD27_1X,
    srcSet: `${CARD27_1X}, ${CARD27_2X} 2x, ${CARD27_3X} 3x`
  },
  CARD28: {
    src: CARD28_1X,
    srcSet: `${CARD28_1X}, ${CARD28_2X} 2x, ${CARD28_3X} 3x`
  },
  CARD29: {
    src: CARD29_1X,
    srcSet: `${CARD29_1X}, ${CARD29_2X} 2x, ${CARD29_3X} 3x`
  },
  CARD30: {
    src: CARD30_1X,
    srcSet: `${CARD30_1X}, ${CARD30_2X} 2x, ${CARD30_3X} 3x`
  },

  //

  ANSWER1_1: {
    src: ANSWER1_1_1X,
    srcSet: `${ANSWER1_1_1X}, ${ANSWER1_1_2X} 2x, ${ANSWER1_1_3X} 3x`
  },
  ANSWER1_2: {
    src: ANSWER1_2_1X,
    srcSet: `${ANSWER1_2_1X}, ${ANSWER1_2_2X} 2x, ${ANSWER1_2_3X} 3x`
  },
  ANSWER1_3: {
    src: ANSWER1_3_1X,
    srcSet: `${ANSWER1_3_1X}, ${ANSWER1_3_2X} 2x, ${ANSWER1_3_3X} 3x`
  },
  ANSWER1_4: {
    src: ANSWER1_4_1X,
    srcSet: `${ANSWER1_4_1X}, ${ANSWER1_4_2X} 2x, ${ANSWER1_4_3X} 3x`
  },
  ANSWER1_5: {
    src: ANSWER1_5_1X,
    srcSet: `${ANSWER1_5_1X}, ${ANSWER1_5_2X} 2x, ${ANSWER1_5_3X} 3x`
  },
  ANSWER1_6: {
    src: ANSWER1_6_1X,
    srcSet: `${ANSWER1_6_1X}, ${ANSWER1_6_2X} 2x, ${ANSWER1_6_3X} 3x`
  },

  ////

  ANSWER2_1: {
    src: ANSWER2_1_1X,
    srcSet: `${ANSWER2_1_1X}, ${ANSWER2_1_2X} 2x, ${ANSWER2_1_3X} 3x`
  },
  ANSWER2_2: {
    src: ANSWER2_2_1X,
    srcSet: `${ANSWER2_2_1X}, ${ANSWER2_2_2X} 2x, ${ANSWER2_2_3X} 3x`
  },
  ANSWER2_3: {
    src: ANSWER2_3_1X,
    srcSet: `${ANSWER2_3_1X}, ${ANSWER2_3_2X} 2x, ${ANSWER2_3_3X} 3x`
  },
  ANSWER2_4: {
    src: ANSWER2_4_1X,
    srcSet: `${ANSWER2_4_1X}, ${ANSWER2_4_2X} 2x, ${ANSWER2_4_3X} 3x`
  },
  ANSWER2_5: {
    src: ANSWER2_5_1X,
    srcSet: `${ANSWER2_5_1X}, ${ANSWER2_5_2X} 2x, ${ANSWER2_5_3X} 3x`
  },
  ANSWER2_6: {
    src: ANSWER2_6_1X,
    srcSet: `${ANSWER2_6_1X}, ${ANSWER2_6_2X} 2x, ${ANSWER2_6_3X} 3x`
  },

  // 

  ANSWER3_1: {
    src: ANSWER3_1_1X,
    srcSet: `${ANSWER3_1_1X}, ${ANSWER3_1_2X} 2x, ${ANSWER3_1_3X} 3x`
  },
  ANSWER3_2: {
    src: ANSWER3_2_1X,
    srcSet: `${ANSWER3_2_1X}, ${ANSWER3_2_2X} 2x, ${ANSWER3_2_3X} 3x`
  },
  ANSWER3_3: {
    src: ANSWER3_3_1X,
    srcSet: `${ANSWER3_3_1X}, ${ANSWER3_3_2X} 2x, ${ANSWER3_3_3X} 3x`
  },
  ANSWER3_4: {
    src: ANSWER3_4_1X,
    srcSet: `${ANSWER3_4_1X}, ${ANSWER3_4_2X} 2x, ${ANSWER3_4_3X} 3x`
  },
  ANSWER3_5: {
    src: ANSWER3_5_1X,
    srcSet: `${ANSWER3_5_1X}, ${ANSWER3_5_2X} 2x, ${ANSWER3_5_3X} 3x`
  },
  ANSWER3_6: {
    src: ANSWER3_6_1X,
    srcSet: `${ANSWER3_6_1X}, ${ANSWER3_6_2X} 2x, ${ANSWER3_6_3X} 3x`
  },

  // 

  ANSWER4_1: {
    src: ANSWER4_1_1X,
    srcSet: `${ANSWER4_1_1X}, ${ANSWER4_1_2X} 2x, ${ANSWER4_1_3X} 3x`
  },
  ANSWER4_2: {
    src: ANSWER4_2_1X,
    srcSet: `${ANSWER4_2_1X}, ${ANSWER4_2_2X} 2x, ${ANSWER4_2_3X} 3x`
  },
  ANSWER4_3: {
    src: ANSWER4_3_1X,
    srcSet: `${ANSWER4_3_1X}, ${ANSWER4_3_2X} 2x, ${ANSWER4_3_3X} 3x`
  },
  ANSWER4_4: {
    src: ANSWER4_4_1X,
    srcSet: `${ANSWER4_4_1X}, ${ANSWER4_4_2X} 2x, ${ANSWER4_4_3X} 3x`
  },
  ANSWER4_5: {
    src: ANSWER4_5_1X,
    srcSet: `${ANSWER4_5_1X}, ${ANSWER4_5_2X} 2x, ${ANSWER4_5_3X} 3x`
  },
  ANSWER4_6: {
    src: ANSWER4_6_1X,
    srcSet: `${ANSWER4_6_1X}, ${ANSWER4_6_2X} 2x, ${ANSWER4_6_3X} 3x`
  },

  // 

  ANSWER5_1: {
    src: ANSWER5_1_1X,
    srcSet: `${ANSWER5_1_1X}, ${ANSWER5_1_2X} 2x, ${ANSWER5_1_3X} 3x`
  },
  ANSWER5_2: {
    src: ANSWER5_2_1X,
    srcSet: `${ANSWER5_2_1X}, ${ANSWER5_2_2X} 2x, ${ANSWER5_2_3X} 3x`
  },
  ANSWER5_3: {
    src: ANSWER5_3_1X,
    srcSet: `${ANSWER5_3_1X}, ${ANSWER5_3_2X} 2x, ${ANSWER5_3_3X} 3x`
  },
  ANSWER5_4: {
    src: ANSWER5_4_1X,
    srcSet: `${ANSWER5_4_1X}, ${ANSWER5_4_2X} 2x, ${ANSWER5_4_3X} 3x`
  },
  ANSWER5_5: {
    src: ANSWER5_5_1X,
    srcSet: `${ANSWER5_5_1X}, ${ANSWER5_5_2X} 2x, ${ANSWER5_5_3X} 3x`
  },
  ANSWER5_6: {
    src: ANSWER5_6_1X,
    srcSet: `${ANSWER5_6_1X}, ${ANSWER5_6_2X} 2x, ${ANSWER5_6_3X} 3x`
  },

  // 

  ANSWER6_1: {
    src: ANSWER6_1_1X,
    srcSet: `${ANSWER6_1_1X}, ${ANSWER6_1_2X} 2x, ${ANSWER6_1_3X} 3x`
  },
  ANSWER6_2: {
    src: ANSWER6_2_1X,
    srcSet: `${ANSWER6_2_1X}, ${ANSWER6_2_2X} 2x, ${ANSWER6_2_3X} 3x`
  },
  ANSWER6_3: {
    src: ANSWER6_3_1X,
    srcSet: `${ANSWER6_3_1X}, ${ANSWER6_3_2X} 2x, ${ANSWER6_3_3X} 3x`
  },
  ANSWER6_4: {
    src: ANSWER6_4_1X,
    srcSet: `${ANSWER6_4_1X}, ${ANSWER6_4_2X} 2x, ${ANSWER6_4_3X} 3x`
  },
  ANSWER6_5: {
    src: ANSWER6_5_1X,
    srcSet: `${ANSWER6_5_1X}, ${ANSWER6_5_2X} 2x, ${ANSWER6_5_3X} 3x`
  },
  ANSWER6_6: {
    src: ANSWER6_6_1X,
    srcSet: `${ANSWER6_6_1X}, ${ANSWER6_6_2X} 2x, ${ANSWER6_6_3X} 3x`
  },

  // 

  ANSWER7_1: {
    src: ANSWER7_1_1X,
    srcSet: `${ANSWER7_1_1X}, ${ANSWER7_1_2X} 2x, ${ANSWER7_1_3X} 3x`
  },
  ANSWER7_2: {
    src: ANSWER7_2_1X,
    srcSet: `${ANSWER7_2_1X}, ${ANSWER7_2_2X} 2x, ${ANSWER7_2_3X} 3x`
  },
  ANSWER7_3: {
    src: ANSWER7_3_1X,
    srcSet: `${ANSWER7_3_1X}, ${ANSWER7_3_2X} 2x, ${ANSWER7_3_3X} 3x`
  },
  ANSWER7_4: {
    src: ANSWER7_4_1X,
    srcSet: `${ANSWER7_4_1X}, ${ANSWER7_4_2X} 2x, ${ANSWER7_4_3X} 3x`
  },
  ANSWER7_5: {
    src: ANSWER7_5_1X,
    srcSet: `${ANSWER7_5_1X}, ${ANSWER7_5_2X} 2x, ${ANSWER7_5_3X} 3x`
  },
  ANSWER7_6: {
    src: ANSWER7_6_1X,
    srcSet: `${ANSWER7_6_1X}, ${ANSWER7_6_2X} 2x, ${ANSWER7_6_3X} 3x`
  },

  // 

  ANSWER8_1: {
    src: ANSWER8_1_1X,
    srcSet: `${ANSWER8_1_1X}, ${ANSWER8_1_2X} 2x, ${ANSWER8_1_3X} 3x`
  },
  ANSWER8_2: {
    src: ANSWER8_2_1X,
    srcSet: `${ANSWER8_2_1X}, ${ANSWER8_2_2X} 2x, ${ANSWER8_2_3X} 3x`
  },
  ANSWER8_3: {
    src: ANSWER8_3_1X,
    srcSet: `${ANSWER8_3_1X}, ${ANSWER8_3_2X} 2x, ${ANSWER8_3_3X} 3x`
  },
  ANSWER8_4: {
    src: ANSWER8_4_1X,
    srcSet: `${ANSWER8_4_1X}, ${ANSWER8_4_2X} 2x, ${ANSWER8_4_3X} 3x`
  },
  ANSWER8_5: {
    src: ANSWER8_5_1X,
    srcSet: `${ANSWER8_5_1X}, ${ANSWER8_5_2X} 2x, ${ANSWER8_5_3X} 3x`
  },
  ANSWER8_6: {
    src: ANSWER8_6_1X,
    srcSet: `${ANSWER8_6_1X}, ${ANSWER8_6_2X} 2x, ${ANSWER8_6_3X} 3x`
  },

  // 

  ANSWER9_1: {
    src: ANSWER9_1_1X,
    srcSet: `${ANSWER9_1_1X}, ${ANSWER9_1_2X} 2x, ${ANSWER9_1_3X} 3x`
  },
  ANSWER9_2: {
    src: ANSWER9_2_1X,
    srcSet: `${ANSWER9_2_1X}, ${ANSWER9_2_2X} 2x, ${ANSWER9_2_3X} 3x`
  },
  ANSWER9_3: {
    src: ANSWER9_3_1X,
    srcSet: `${ANSWER9_3_1X}, ${ANSWER9_3_2X} 2x, ${ANSWER9_3_3X} 3x`
  },
  ANSWER9_4: {
    src: ANSWER9_4_1X,
    srcSet: `${ANSWER9_4_1X}, ${ANSWER9_4_2X} 2x, ${ANSWER9_4_3X} 3x`
  },
  ANSWER9_5: {
    src: ANSWER9_5_1X,
    srcSet: `${ANSWER9_5_1X}, ${ANSWER9_5_2X} 2x, ${ANSWER9_5_3X} 3x`
  },
  ANSWER9_6: {
    src: ANSWER9_6_1X,
    srcSet: `${ANSWER9_6_1X}, ${ANSWER9_6_2X} 2x, ${ANSWER9_6_3X} 3x`
  },

  // 

  ANSWER10_1: {
    src: ANSWER10_1_1X,
    srcSet: `${ANSWER10_1_1X}, ${ANSWER10_1_2X} 2x, ${ANSWER10_1_3X} 3x`
  },
  ANSWER10_2: {
    src: ANSWER10_2_1X,
    srcSet: `${ANSWER10_2_1X}, ${ANSWER10_2_2X} 2x, ${ANSWER10_2_3X} 3x`
  },
  ANSWER10_3: {
    src: ANSWER10_3_1X,
    srcSet: `${ANSWER10_3_1X}, ${ANSWER10_3_2X} 2x, ${ANSWER10_3_3X} 3x`
  },
  ANSWER10_4: {
    src: ANSWER10_4_1X,
    srcSet: `${ANSWER10_4_1X}, ${ANSWER10_4_2X} 2x, ${ANSWER10_4_3X} 3x`
  },
  ANSWER10_5: {
    src: ANSWER10_5_1X,
    srcSet: `${ANSWER10_5_1X}, ${ANSWER10_5_2X} 2x, ${ANSWER10_5_3X} 3x`
  },
  ANSWER10_6: {
    src: ANSWER10_6_1X,
    srcSet: `${ANSWER10_6_1X}, ${ANSWER10_6_2X} 2x, ${ANSWER10_6_3X} 3x`
  },

  ////

  ANSWER11_1: {
    src: ANSWER11_1_1X,
    srcSet: `${ANSWER11_1_1X}, ${ANSWER11_1_2X} 2x, ${ANSWER11_1_3X} 3x`
  },
  ANSWER11_2: {
    src: ANSWER11_2_1X,
    srcSet: `${ANSWER11_2_1X}, ${ANSWER11_2_2X} 2x, ${ANSWER11_2_3X} 3x`
  },
  ANSWER11_3: {
    src: ANSWER11_3_1X,
    srcSet: `${ANSWER11_3_1X}, ${ANSWER11_3_2X} 2x, ${ANSWER11_3_3X} 3x`
  },
  ANSWER11_4: {
    src: ANSWER11_4_1X,
    srcSet: `${ANSWER11_4_1X}, ${ANSWER11_4_2X} 2x, ${ANSWER11_4_3X} 3x`
  },
  ANSWER11_5: {
    src: ANSWER11_5_1X,
    srcSet: `${ANSWER11_5_1X}, ${ANSWER11_5_2X} 2x, ${ANSWER11_5_3X} 3x`
  },
  ANSWER11_6: {
    src: ANSWER11_6_1X,
    srcSet: `${ANSWER11_6_1X}, ${ANSWER11_6_2X} 2x, ${ANSWER11_6_3X} 3x`
  },

  ////

  ANSWER12_1: {
    src: ANSWER12_1_1X,
    srcSet: `${ANSWER12_1_1X}, ${ANSWER12_1_2X} 2x, ${ANSWER12_1_3X} 3x`
  },
  ANSWER12_2: {
    src: ANSWER12_2_1X,
    srcSet: `${ANSWER12_2_1X}, ${ANSWER12_2_2X} 2x, ${ANSWER12_2_3X} 3x`
  },
  ANSWER12_3: {
    src: ANSWER12_3_1X,
    srcSet: `${ANSWER12_3_1X}, ${ANSWER12_3_2X} 2x, ${ANSWER12_3_3X} 3x`
  },
  ANSWER12_4: {
    src: ANSWER12_4_1X,
    srcSet: `${ANSWER12_4_1X}, ${ANSWER12_4_2X} 2x, ${ANSWER12_4_3X} 3x`
  },
  ANSWER12_5: {
    src: ANSWER12_5_1X,
    srcSet: `${ANSWER12_5_1X}, ${ANSWER12_5_2X} 2x, ${ANSWER12_5_3X} 3x`
  },
  ANSWER12_6: {
    src: ANSWER12_6_1X,
    srcSet: `${ANSWER12_6_1X}, ${ANSWER12_6_2X} 2x, ${ANSWER12_6_3X} 3x`
  },

  // 

  ANSWER13_1: {
    src: ANSWER13_1_1X,
    srcSet: `${ANSWER13_1_1X}, ${ANSWER13_1_2X} 2x, ${ANSWER13_1_3X} 3x`
  },
  ANSWER13_2: {
    src: ANSWER13_2_1X,
    srcSet: `${ANSWER13_2_1X}, ${ANSWER13_2_2X} 2x, ${ANSWER13_2_3X} 3x`
  },
  ANSWER13_3: {
    src: ANSWER13_3_1X,
    srcSet: `${ANSWER13_3_1X}, ${ANSWER13_3_2X} 2x, ${ANSWER13_3_3X} 3x`
  },
  ANSWER13_4: {
    src: ANSWER13_4_1X,
    srcSet: `${ANSWER13_4_1X}, ${ANSWER13_4_2X} 2x, ${ANSWER13_4_3X} 3x`
  },
  ANSWER13_5: {
    src: ANSWER13_5_1X,
    srcSet: `${ANSWER13_5_1X}, ${ANSWER13_5_2X} 2x, ${ANSWER13_5_3X} 3x`
  },
  ANSWER13_6: {
    src: ANSWER13_6_1X,
    srcSet: `${ANSWER13_6_1X}, ${ANSWER13_6_2X} 2x, ${ANSWER13_6_3X} 3x`
  },

  // 

  ANSWER14_1: {
    src: ANSWER14_1_1X,
    srcSet: `${ANSWER14_1_1X}, ${ANSWER14_1_2X} 2x, ${ANSWER14_1_3X} 3x`
  },
  ANSWER14_2: {
    src: ANSWER14_2_1X,
    srcSet: `${ANSWER14_2_1X}, ${ANSWER14_2_2X} 2x, ${ANSWER14_2_3X} 3x`
  },
  ANSWER14_3: {
    src: ANSWER14_3_1X,
    srcSet: `${ANSWER14_3_1X}, ${ANSWER14_3_2X} 2x, ${ANSWER14_3_3X} 3x`
  },
  ANSWER14_4: {
    src: ANSWER14_4_1X,
    srcSet: `${ANSWER14_4_1X}, ${ANSWER14_4_2X} 2x, ${ANSWER14_4_3X} 3x`
  },
  ANSWER14_5: {
    src: ANSWER14_5_1X,
    srcSet: `${ANSWER14_5_1X}, ${ANSWER14_5_2X} 2x, ${ANSWER14_5_3X} 3x`
  },
  ANSWER14_6: {
    src: ANSWER14_6_1X,
    srcSet: `${ANSWER14_6_1X}, ${ANSWER14_6_2X} 2x, ${ANSWER14_6_3X} 3x`
  },

  // 

  ANSWER15_1: {
    src: ANSWER15_1_1X,
    srcSet: `${ANSWER15_1_1X}, ${ANSWER15_1_2X} 2x, ${ANSWER15_1_3X} 3x`
  },
  ANSWER15_2: {
    src: ANSWER15_2_1X,
    srcSet: `${ANSWER15_2_1X}, ${ANSWER15_2_2X} 2x, ${ANSWER15_2_3X} 3x`
  },
  ANSWER15_3: {
    src: ANSWER15_3_1X,
    srcSet: `${ANSWER15_3_1X}, ${ANSWER15_3_2X} 2x, ${ANSWER15_3_3X} 3x`
  },
  ANSWER15_4: {
    src: ANSWER15_4_1X,
    srcSet: `${ANSWER15_4_1X}, ${ANSWER15_4_2X} 2x, ${ANSWER15_4_3X} 3x`
  },
  ANSWER15_5: {
    src: ANSWER15_5_1X,
    srcSet: `${ANSWER15_5_1X}, ${ANSWER15_5_2X} 2x, ${ANSWER15_5_3X} 3x`
  },
  ANSWER15_6: {
    src: ANSWER15_6_1X,
    srcSet: `${ANSWER15_6_1X}, ${ANSWER15_6_2X} 2x, ${ANSWER15_6_3X} 3x`
  },

  // 

  ANSWER16_1: {
    src: ANSWER16_1_1X,
    srcSet: `${ANSWER16_1_1X}, ${ANSWER16_1_2X} 2x, ${ANSWER16_1_3X} 3x`
  },
  ANSWER16_2: {
    src: ANSWER16_2_1X,
    srcSet: `${ANSWER16_2_1X}, ${ANSWER16_2_2X} 2x, ${ANSWER16_2_3X} 3x`
  },
  ANSWER16_3: {
    src: ANSWER16_3_1X,
    srcSet: `${ANSWER16_3_1X}, ${ANSWER16_3_2X} 2x, ${ANSWER16_3_3X} 3x`
  },
  ANSWER16_4: {
    src: ANSWER16_4_1X,
    srcSet: `${ANSWER16_4_1X}, ${ANSWER16_4_2X} 2x, ${ANSWER16_4_3X} 3x`
  },
  ANSWER16_5: {
    src: ANSWER16_5_1X,
    srcSet: `${ANSWER16_5_1X}, ${ANSWER16_5_2X} 2x, ${ANSWER16_5_3X} 3x`
  },
  ANSWER16_6: {
    src: ANSWER16_6_1X,
    srcSet: `${ANSWER16_6_1X}, ${ANSWER16_6_2X} 2x, ${ANSWER16_6_3X} 3x`
  },

  // 

  ANSWER17_1: {
    src: ANSWER17_1_1X,
    srcSet: `${ANSWER17_1_1X}, ${ANSWER17_1_2X} 2x, ${ANSWER17_1_3X} 3x`
  },
  ANSWER17_2: {
    src: ANSWER17_2_1X,
    srcSet: `${ANSWER17_2_1X}, ${ANSWER17_2_2X} 2x, ${ANSWER17_2_3X} 3x`
  },
  ANSWER17_3: {
    src: ANSWER17_3_1X,
    srcSet: `${ANSWER17_3_1X}, ${ANSWER17_3_2X} 2x, ${ANSWER17_3_3X} 3x`
  },
  ANSWER17_4: {
    src: ANSWER17_4_1X,
    srcSet: `${ANSWER17_4_1X}, ${ANSWER17_4_2X} 2x, ${ANSWER17_4_3X} 3x`
  },
  ANSWER17_5: {
    src: ANSWER17_5_1X,
    srcSet: `${ANSWER17_5_1X}, ${ANSWER17_5_2X} 2x, ${ANSWER17_5_3X} 3x`
  },
  ANSWER17_6: {
    src: ANSWER17_6_1X,
    srcSet: `${ANSWER17_6_1X}, ${ANSWER17_6_2X} 2x, ${ANSWER17_6_3X} 3x`
  },

  // 

  ANSWER18_1: {
    src: ANSWER18_1_1X,
    srcSet: `${ANSWER18_1_1X}, ${ANSWER18_1_2X} 2x, ${ANSWER18_1_3X} 3x`
  },
  ANSWER18_2: {
    src: ANSWER18_2_1X,
    srcSet: `${ANSWER18_2_1X}, ${ANSWER18_2_2X} 2x, ${ANSWER18_2_3X} 3x`
  },
  ANSWER18_3: {
    src: ANSWER18_3_1X,
    srcSet: `${ANSWER18_3_1X}, ${ANSWER18_3_2X} 2x, ${ANSWER18_3_3X} 3x`
  },
  ANSWER18_4: {
    src: ANSWER18_4_1X,
    srcSet: `${ANSWER18_4_1X}, ${ANSWER18_4_2X} 2x, ${ANSWER18_4_3X} 3x`
  },
  ANSWER18_5: {
    src: ANSWER18_5_1X,
    srcSet: `${ANSWER18_5_1X}, ${ANSWER18_5_2X} 2x, ${ANSWER18_5_3X} 3x`
  },
  ANSWER18_6: {
    src: ANSWER18_6_1X,
    srcSet: `${ANSWER18_6_1X}, ${ANSWER18_6_2X} 2x, ${ANSWER18_6_3X} 3x`
  },

  // 

  ANSWER19_1: {
    src: ANSWER19_1_1X,
    srcSet: `${ANSWER19_1_1X}, ${ANSWER19_1_2X} 2x, ${ANSWER19_1_3X} 3x`
  },
  ANSWER19_2: {
    src: ANSWER19_2_1X,
    srcSet: `${ANSWER19_2_1X}, ${ANSWER19_2_2X} 2x, ${ANSWER19_2_3X} 3x`
  },
  ANSWER19_3: {
    src: ANSWER19_3_1X,
    srcSet: `${ANSWER19_3_1X}, ${ANSWER19_3_2X} 2x, ${ANSWER19_3_3X} 3x`
  },
  ANSWER19_4: {
    src: ANSWER19_4_1X,
    srcSet: `${ANSWER19_4_1X}, ${ANSWER19_4_2X} 2x, ${ANSWER19_4_3X} 3x`
  },
  ANSWER19_5: {
    src: ANSWER19_5_1X,
    srcSet: `${ANSWER19_5_1X}, ${ANSWER19_5_2X} 2x, ${ANSWER19_5_3X} 3x`
  },
  ANSWER19_6: {
    src: ANSWER19_6_1X,
    srcSet: `${ANSWER19_6_1X}, ${ANSWER19_6_2X} 2x, ${ANSWER19_6_3X} 3x`
  },

  // 

  ANSWER20_1: {
    src: ANSWER20_1_1X,
    srcSet: `${ANSWER20_1_1X}, ${ANSWER20_1_2X} 2x, ${ANSWER20_1_3X} 3x`
  },
  ANSWER20_2: {
    src: ANSWER20_2_1X,
    srcSet: `${ANSWER20_2_1X}, ${ANSWER20_2_2X} 2x, ${ANSWER20_2_3X} 3x`
  },
  ANSWER20_3: {
    src: ANSWER20_3_1X,
    srcSet: `${ANSWER20_3_1X}, ${ANSWER20_3_2X} 2x, ${ANSWER20_3_3X} 3x`
  },
  ANSWER20_4: {
    src: ANSWER20_4_1X,
    srcSet: `${ANSWER20_4_1X}, ${ANSWER20_4_2X} 2x, ${ANSWER20_4_3X} 3x`
  },
  ANSWER20_5: {
    src: ANSWER20_5_1X,
    srcSet: `${ANSWER20_5_1X}, ${ANSWER20_5_2X} 2x, ${ANSWER20_5_3X} 3x`
  },
  ANSWER20_6: {
    src: ANSWER20_6_1X,
    srcSet: `${ANSWER20_6_1X}, ${ANSWER20_6_2X} 2x, ${ANSWER20_6_3X} 3x`
  },

  ////

  ANSWER21_1: {
    src: ANSWER21_1_1X,
    srcSet: `${ANSWER21_1_1X}, ${ANSWER21_1_2X} 2x, ${ANSWER21_1_3X} 3x`
  },
  ANSWER21_2: {
    src: ANSWER21_2_1X,
    srcSet: `${ANSWER21_2_1X}, ${ANSWER21_2_2X} 2x, ${ANSWER21_2_3X} 3x`
  },
  ANSWER21_3: {
    src: ANSWER21_3_1X,
    srcSet: `${ANSWER21_3_1X}, ${ANSWER21_3_2X} 2x, ${ANSWER21_3_3X} 3x`
  },
  ANSWER21_4: {
    src: ANSWER21_4_1X,
    srcSet: `${ANSWER21_4_1X}, ${ANSWER21_4_2X} 2x, ${ANSWER21_4_3X} 3x`
  },
  ANSWER21_5: {
    src: ANSWER21_5_1X,
    srcSet: `${ANSWER21_5_1X}, ${ANSWER21_5_2X} 2x, ${ANSWER21_5_3X} 3x`
  },
  ANSWER21_6: {
    src: ANSWER21_6_1X,
    srcSet: `${ANSWER21_6_1X}, ${ANSWER21_6_2X} 2x, ${ANSWER21_6_3X} 3x`
  },

  ////

  ANSWER22_1: {
    src: ANSWER22_1_1X,
    srcSet: `${ANSWER22_1_1X}, ${ANSWER22_1_2X} 2x, ${ANSWER22_1_3X} 3x`
  },
  ANSWER22_2: {
    src: ANSWER22_2_1X,
    srcSet: `${ANSWER22_2_1X}, ${ANSWER22_2_2X} 2x, ${ANSWER22_2_3X} 3x`
  },
  ANSWER22_3: {
    src: ANSWER22_3_1X,
    srcSet: `${ANSWER22_3_1X}, ${ANSWER22_3_2X} 2x, ${ANSWER22_3_3X} 3x`
  },
  ANSWER22_4: {
    src: ANSWER22_4_1X,
    srcSet: `${ANSWER22_4_1X}, ${ANSWER22_4_2X} 2x, ${ANSWER22_4_3X} 3x`
  },
  ANSWER22_5: {
    src: ANSWER22_5_1X,
    srcSet: `${ANSWER22_5_1X}, ${ANSWER22_5_2X} 2x, ${ANSWER22_5_3X} 3x`
  },
  ANSWER22_6: {
    src: ANSWER22_6_1X,
    srcSet: `${ANSWER22_6_1X}, ${ANSWER22_6_2X} 2x, ${ANSWER22_6_3X} 3x`
  },

  // 

  ANSWER23_1: {
    src: ANSWER23_1_1X,
    srcSet: `${ANSWER23_1_1X}, ${ANSWER23_1_2X} 2x, ${ANSWER23_1_3X} 3x`
  },
  ANSWER23_2: {
    src: ANSWER23_2_1X,
    srcSet: `${ANSWER23_2_1X}, ${ANSWER23_2_2X} 2x, ${ANSWER23_2_3X} 3x`
  },
  ANSWER23_3: {
    src: ANSWER23_3_1X,
    srcSet: `${ANSWER23_3_1X}, ${ANSWER23_3_2X} 2x, ${ANSWER23_3_3X} 3x`
  },
  ANSWER23_4: {
    src: ANSWER23_4_1X,
    srcSet: `${ANSWER23_4_1X}, ${ANSWER23_4_2X} 2x, ${ANSWER23_4_3X} 3x`
  },
  ANSWER23_5: {
    src: ANSWER23_5_1X,
    srcSet: `${ANSWER23_5_1X}, ${ANSWER23_5_2X} 2x, ${ANSWER23_5_3X} 3x`
  },
  ANSWER23_6: {
    src: ANSWER23_6_1X,
    srcSet: `${ANSWER23_6_1X}, ${ANSWER23_6_2X} 2x, ${ANSWER23_6_3X} 3x`
  },

  // 

  ANSWER24_1: {
    src: ANSWER24_1_1X,
    srcSet: `${ANSWER24_1_1X}, ${ANSWER24_1_2X} 2x, ${ANSWER24_1_3X} 3x`
  },
  ANSWER24_2: {
    src: ANSWER24_2_1X,
    srcSet: `${ANSWER24_2_1X}, ${ANSWER24_2_2X} 2x, ${ANSWER24_2_3X} 3x`
  },
  ANSWER24_3: {
    src: ANSWER24_3_1X,
    srcSet: `${ANSWER24_3_1X}, ${ANSWER24_3_2X} 2x, ${ANSWER24_3_3X} 3x`
  },
  ANSWER24_4: {
    src: ANSWER24_4_1X,
    srcSet: `${ANSWER24_4_1X}, ${ANSWER24_4_2X} 2x, ${ANSWER24_4_3X} 3x`
  },
  ANSWER24_5: {
    src: ANSWER24_5_1X,
    srcSet: `${ANSWER24_5_1X}, ${ANSWER24_5_2X} 2x, ${ANSWER24_5_3X} 3x`
  },
  ANSWER24_6: {
    src: ANSWER24_6_1X,
    srcSet: `${ANSWER24_6_1X}, ${ANSWER24_6_2X} 2x, ${ANSWER24_6_3X} 3x`
  },

  // 

  ANSWER25_1: {
    src: ANSWER25_1_1X,
    srcSet: `${ANSWER25_1_1X}, ${ANSWER25_1_2X} 2x, ${ANSWER25_1_3X} 3x`
  },
  ANSWER25_2: {
    src: ANSWER25_2_1X,
    srcSet: `${ANSWER25_2_1X}, ${ANSWER25_2_2X} 2x, ${ANSWER25_2_3X} 3x`
  },
  ANSWER25_3: {
    src: ANSWER25_3_1X,
    srcSet: `${ANSWER25_3_1X}, ${ANSWER25_3_2X} 2x, ${ANSWER25_3_3X} 3x`
  },
  ANSWER25_4: {
    src: ANSWER25_4_1X,
    srcSet: `${ANSWER25_4_1X}, ${ANSWER25_4_2X} 2x, ${ANSWER25_4_3X} 3x`
  },
  ANSWER25_5: {
    src: ANSWER25_5_1X,
    srcSet: `${ANSWER25_5_1X}, ${ANSWER25_5_2X} 2x, ${ANSWER25_5_3X} 3x`
  },
  ANSWER25_6: {
    src: ANSWER25_6_1X,
    srcSet: `${ANSWER25_6_1X}, ${ANSWER25_6_2X} 2x, ${ANSWER25_6_3X} 3x`
  },

  // 

  ANSWER26_1: {
    src: ANSWER26_1_1X,
    srcSet: `${ANSWER26_1_1X}, ${ANSWER26_1_2X} 2x, ${ANSWER26_1_3X} 3x`
  },
  ANSWER26_2: {
    src: ANSWER26_2_1X,
    srcSet: `${ANSWER26_2_1X}, ${ANSWER26_2_2X} 2x, ${ANSWER26_2_3X} 3x`
  },
  ANSWER26_3: {
    src: ANSWER26_3_1X,
    srcSet: `${ANSWER26_3_1X}, ${ANSWER26_3_2X} 2x, ${ANSWER26_3_3X} 3x`
  },
  ANSWER26_4: {
    src: ANSWER26_4_1X,
    srcSet: `${ANSWER26_4_1X}, ${ANSWER26_4_2X} 2x, ${ANSWER26_4_3X} 3x`
  },
  ANSWER26_5: {
    src: ANSWER26_5_1X,
    srcSet: `${ANSWER26_5_1X}, ${ANSWER26_5_2X} 2x, ${ANSWER26_5_3X} 3x`
  },
  ANSWER26_6: {
    src: ANSWER26_6_1X,
    srcSet: `${ANSWER26_6_1X}, ${ANSWER26_6_2X} 2x, ${ANSWER26_6_3X} 3x`
  },

  // 

  ANSWER27_1: {
    src: ANSWER27_1_1X,
    srcSet: `${ANSWER27_1_1X}, ${ANSWER27_1_2X} 2x, ${ANSWER27_1_3X} 3x`
  },
  ANSWER27_2: {
    src: ANSWER27_2_1X,
    srcSet: `${ANSWER27_2_1X}, ${ANSWER27_2_2X} 2x, ${ANSWER27_2_3X} 3x`
  },
  ANSWER27_3: {
    src: ANSWER27_3_1X,
    srcSet: `${ANSWER27_3_1X}, ${ANSWER27_3_2X} 2x, ${ANSWER27_3_3X} 3x`
  },
  ANSWER27_4: {
    src: ANSWER27_4_1X,
    srcSet: `${ANSWER27_4_1X}, ${ANSWER27_4_2X} 2x, ${ANSWER27_4_3X} 3x`
  },
  ANSWER27_5: {
    src: ANSWER27_5_1X,
    srcSet: `${ANSWER27_5_1X}, ${ANSWER27_5_2X} 2x, ${ANSWER27_5_3X} 3x`
  },
  ANSWER27_6: {
    src: ANSWER27_6_1X,
    srcSet: `${ANSWER27_6_1X}, ${ANSWER27_6_2X} 2x, ${ANSWER27_6_3X} 3x`
  },

  // 

  ANSWER28_1: {
    src: ANSWER28_1_1X,
    srcSet: `${ANSWER28_1_1X}, ${ANSWER28_1_2X} 2x, ${ANSWER28_1_3X} 3x`
  },
  ANSWER28_2: {
    src: ANSWER28_2_1X,
    srcSet: `${ANSWER28_2_1X}, ${ANSWER28_2_2X} 2x, ${ANSWER28_2_3X} 3x`
  },
  ANSWER28_3: {
    src: ANSWER28_3_1X,
    srcSet: `${ANSWER28_3_1X}, ${ANSWER28_3_2X} 2x, ${ANSWER28_3_3X} 3x`
  },
  ANSWER28_4: {
    src: ANSWER28_4_1X,
    srcSet: `${ANSWER28_4_1X}, ${ANSWER28_4_2X} 2x, ${ANSWER28_4_3X} 3x`
  },
  ANSWER28_5: {
    src: ANSWER28_5_1X,
    srcSet: `${ANSWER28_5_1X}, ${ANSWER28_5_2X} 2x, ${ANSWER28_5_3X} 3x`
  },
  ANSWER28_6: {
    src: ANSWER28_6_1X,
    srcSet: `${ANSWER28_6_1X}, ${ANSWER28_6_2X} 2x, ${ANSWER28_6_3X} 3x`
  },

  // 

  ANSWER29_1: {
    src: ANSWER29_1_1X,
    srcSet: `${ANSWER29_1_1X}, ${ANSWER29_1_2X} 2x, ${ANSWER29_1_3X} 3x`
  },
  ANSWER29_2: {
    src: ANSWER29_2_1X,
    srcSet: `${ANSWER29_2_1X}, ${ANSWER29_2_2X} 2x, ${ANSWER29_2_3X} 3x`
  },
  ANSWER29_3: {
    src: ANSWER29_3_1X,
    srcSet: `${ANSWER29_3_1X}, ${ANSWER29_3_2X} 2x, ${ANSWER29_3_3X} 3x`
  },
  ANSWER29_4: {
    src: ANSWER29_4_1X,
    srcSet: `${ANSWER29_4_1X}, ${ANSWER29_4_2X} 2x, ${ANSWER29_4_3X} 3x`
  },
  ANSWER29_5: {
    src: ANSWER29_5_1X,
    srcSet: `${ANSWER29_5_1X}, ${ANSWER29_5_2X} 2x, ${ANSWER29_5_3X} 3x`
  },
  ANSWER29_6: {
    src: ANSWER29_6_1X,
    srcSet: `${ANSWER29_6_1X}, ${ANSWER29_6_2X} 2x, ${ANSWER29_6_3X} 3x`
  },

  // 

  ANSWER30_1: {
    src: ANSWER30_1_1X,
    srcSet: `${ANSWER30_1_1X}, ${ANSWER30_1_2X} 2x, ${ANSWER30_1_3X} 3x`
  },
  ANSWER30_2: {
    src: ANSWER30_2_1X,
    srcSet: `${ANSWER30_2_1X}, ${ANSWER30_2_2X} 2x, ${ANSWER30_2_3X} 3x`
  },
  ANSWER30_3: {
    src: ANSWER30_3_1X,
    srcSet: `${ANSWER30_3_1X}, ${ANSWER30_3_2X} 2x, ${ANSWER30_3_3X} 3x`
  },
  ANSWER30_4: {
    src: ANSWER30_4_1X,
    srcSet: `${ANSWER30_4_1X}, ${ANSWER30_4_2X} 2x, ${ANSWER30_4_3X} 3x`
  },
  ANSWER30_5: {
    src: ANSWER30_5_1X,
    srcSet: `${ANSWER30_5_1X}, ${ANSWER30_5_2X} 2x, ${ANSWER30_5_3X} 3x`
  },
  ANSWER30_6: {
    src: ANSWER30_6_1X,
    srcSet: `${ANSWER30_6_1X}, ${ANSWER30_6_2X} 2x, ${ANSWER30_6_3X} 3x`
  },
};