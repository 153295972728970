import _debounce from 'lodash/debounce';
import React from 'react';

import { ModeApplePay, ModeGooglePay } from '../constants';
import { checkPaymentMethod as checkApplePaymentMethod } from '../utils/apple-pay';
import { checkPaymentMethod as checkGooglePaymentMethod } from '../utils/google-pay';

const FORM_CONTAINER_ID = 'solid-payment-form-container';

const INPUT_STYLES = {
  'border-radius': '4px',
  'border-color': 'rgba(184, 185, 195, 0.3)',
  'background-color': '#F7F7F7',
  height: '3em',
};

const LABEL_STYLES = {
  color: '#2d2d2d',
  'font-size': '0.75rem !important',
  'font-weight': '600',
};

const NOT_EMPTY_LABEL_STYLES = {
  color: '#01132c',
};

let isApplePayAvailable = null;
let isGooglePayAvailable = null;

class CardElement extends React.Component {
  componentDidMount() {
    this._promiseUpdate = Promise.resolve();
    this._initForm();
  }

  componentWillUnmount() {
    this.form?.destroy();
  }

  componentDidUpdate(prevProps) {
    if (this.props.options?.orderDetails !== prevProps.options?.orderDetails) {
      this._initForm();
    }

    if (this.props.product?.id !== prevProps.product?.id) {
      this._updateProduct();
    }

    this._updateAppleBtn();
    this._updateGoogleBtn();
  }

  _initForm = () => {
    const { options } = this.props;
    const orderDetails = options?.orderDetails;
    const labelStyles = this.props.labelStyles || LABEL_STYLES;
    const inputStyles = this.props.inputStyles || INPUT_STYLES;
    const notEmptyLabelStyles =
      this.props.notEmptyLabelStyles || NOT_EMPTY_LABEL_STYLES;

    if (orderDetails) {
      if (!this.form) {
        const data = {
          merchantData: orderDetails,
          iframeParams: {
            containerId: FORM_CONTAINER_ID,
          },
          formParams: {
            formTypeClass: 'default',
            googleFontLink:
              '//fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap',
            ...(options?.formParams || {}),
          },
          styles: {
            form_body: {
              'min-width': 'auto',
              'font-family': 'Open Sans',
            },
            body_errors: {
              display: 'none',
            },
            card_number: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            expiry_date: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            card_cvv: {
              input: {
                ...inputStyles,
                '::placeholder': {
                  'font-size': '1em',
                },
                ':focus~.tooltip': {
                  display: options.isCvvTooltip ? 'block' : 'none',
                }
              },
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
              '.tooltip-icon': {
                'pointer-events': 'none',
                display: options.isCvvTooltip ? 'block' : 'none',
                top: '42px',
                right: '12px',
                '::before': {
                  background: '#499B92'
                }
              },
              '.error-text': {
                display: 'none',
              },
              '.tooltip': {
                'border-radius': '10px',
                top: '-2.5em',
                right: '3px',
                width: '11.3em',
                'min-height': '4.5em',
                '::after': {
                  bottom: '-15px',
                  top: 'unset',
                  right: '-10px',
                  transform: 'rotate(135deg)'
                }
              }
            },
            card_holder: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            zip_code: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            email: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            submit_button: {
              'background-color': "#03BD12",
              'border-radius': '32px',
              'text-transform': 'none',
              'font-size': '20px',
              'font-weight': '600',
              'padding': '0em 1em',
              'min-height': '55px',
              '-webkit-tap-highlight-color': 'transparent',
              '.title': {
                'margin-left': '20px',
                'letter-spacing': '-0.57px',
              },
              '.title-icon': {
                width: '20px',
                height: '20px',
                'background-color': 'red',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                position: 'absolute',
                top: '23px',
                left: '20px',
                'background': `url(${require('./lock.png')}) center center / contain no-repeat !important`,
                // display: options.isCvvTooltip ? 'inline-block' : 'none',
              },
              ':disabled': {
                'background-color': "#03BD12",
                'opacity': '0.5',
              }
            }
          },
          applePayButtonParams: {
            ...(options?.applePayButtonParams || {}),
          },
          googlePayButtonParams: {
            ...(options?.googlePayButtonParams || {}),
          },
        };

        const form = (this.form = window.PaymentFormSdk.init(data));

        form.on('mounted', (e) => {
          console.log(e?.data);
          this.mounted = true;
          if (e?.data.entity === 'form') {
            this._updateProduct();
            this.props.onReady && this.props.onReady(e);
          } else if (e?.data.entity === 'applebtn') {
            this._updateAppleBtn();
            this._checkApplePaymentMethod();
          } else if (e?.data.entity === 'googlebtn') {
            this._updateGoogleBtn();
            this._checkGooglePaymentMethod();
          }
        });
        form.on('submit', (e) => {
          console.log(e?.data);
          if (e?.data?.entity === 'form') {
            this.props.onSubmit && this.props.onSubmit(e);
          } else if (e?.data?.entity === 'applebtn') {
            e.method = 'applepay';
            this.props.onApplePaySubmit && this.props.onApplePaySubmit(e);
          } else if (e?.data?.entity === 'googlebtn') {
            e.method = 'googlepay';
            this.props.onGooglePaySubmit && this.props.onGooglePaySubmit(e);
          }
        });
        form.on('success', (e) => {
          console.log(e?.data);
          this.props.onSuccess &&
            this.props.onSuccess({
              transaction_id: e?.data?.order?.subscription_id,
              amount: e?.data?.order?.amount / 100,
              currency: e?.data?.order?.currency,
              order: e?.data?.order,
              method: e?.data?.entity === 'form' ? 'card' : e?.data?.entity,
            });
        });
        form.on('fail', (e) => {
          console.log(e?.data);
          this.props.onError && this.props.onError(e?.data);
        });
        form.on('verify', (e) => {
          console.log(e?.data);
        });
        form.on('interaction', (e) => {
          console.log(e?.data);
          if (e.data?.target?.interaction === 'change') {
            e.valid = this.isValid = Object.keys(
              e?.data?.cardForm?.fields || {}
            ).reduce(
              (r, v) => r && !!e?.data?.cardForm?.fields[v].isValid,
              true
            );
            e.empty = !e?.data?.cardForm.isTouched;
            this.props.onChange && this.props.onChange(e);
          } else if (e.data?.target?.interaction === 'click') {
            if (e.data?.target?.name === 'applePay') {
              this.props.onApplePayClick && this.props.onApplePayClick();
            } else if (e.data?.target?.name === 'googlePay') {
              this.props.onGooglePayClick && this.props.onGooglePayClick();
            }
          }
        });
        form.on('error', (e) => {
          console.error(e?.data);
        });
        form.on('orderStatus', (e) => {
          console.log(e?.data);
          let zip;
          try {
            zip = Object.values(e?.data?.response?.transactions)?.[0]?.billing_details?.zip;
          }
          catch (ex) { }
          this.props.onUserInfoChange &&
            this.props.onUserInfoChange({
              email: e?.data?.response?.order?.customer_email,
              zip,
            });
        });
      } else {
        this._updateProduct();
      }
    } else {
      if (this.form) {
        this.form.destroy();
        delete this.form;
      }
    }
  };

  _updateProduct = _debounce(() => {
    const orderDetails = this.props.options?.orderDetails;
    const productId = this.props.product?.id;
    const intent = orderDetails?.products?.[productId] || orderDetails?.one_time_products?.[productId] || orderDetails;
    if (this.form && this.mounted && intent) {
      console.log('solidgate product update', this.props.product?.id)
      const p = this._promiseUpdate.then(() => this.form.update({
        partialIntent: intent.paymentIntent,
        signature: intent.signature,
      }))
        .then(() => {
          console.log('solidgate product updated')
        });
      this._promiseUpdate = p.catch(() => Promise.resolve());
    }
  }, 250);

  _updateAppleBtn = () => {
    const { options } = this.props;
    const btn = document.getElementById('apple-pay');
    if (btn) {
      if (isApplePayAvailable) {
        if (options?.applePayButtonParams?.disabled) {
          btn.style = 'height: 50px; pointer-events: none; opacity: 0.5;';
        } else {
          btn.style = 'height: 50px; pointer-events: initial; opacity: 1;';
        }
      } else {
        btn.style = 'height: 0; overflow: hidden; margin: 0;';
      }
    }
  };

  _updateGoogleBtn = () => {
    console.log('_updateGoogleBtn');
    const { options } = this.props;
    console.log('options?.googlePayButtonParams?.disabled', options?.applePayButtonParams)
    const btn = document.getElementById(options?.googlePayButtonParams?.containerId);
    if (btn) {
      if (isGooglePayAvailable) {
        if (options?.googlePayButtonParams?.disabled) {
          btn.style = 'height: 50px; pointer-events: none; opacity: 0.5;';
        } else {
          btn.style = 'height: 50px; pointer-events: initial; opacity: 1;';
        }
      } else {
        btn.style = 'height: 0; overflow: hidden; margin: 0;';
      }
    }
  };

  _checkApplePaymentMethod = () => {
    const m = this.props.options?.applePayButtonParams?.mode;
    if (isApplePayAvailable === null) {
      if (m === ModeApplePay.ENABLED || m === ModeApplePay.ENABLED_IF_WITH_CARD) {
        checkApplePaymentMethod(m === ModeApplePay.ENABLED_IF_WITH_CARD)
          .then((r) => {
            isApplePayAvailable = r;
          })
          .catch(() => {
            isApplePayAvailable = false;
          })
          .then(() => {
            this._updateAppleBtn();
          });
      }
    }
  };

  _checkGooglePaymentMethod = () => {
    const m = this.props.options?.googlePayButtonParams?.mode;
    if (isGooglePayAvailable === null) {
      if (m === ModeGooglePay.ENABLED || m === ModeGooglePay.ENABLED_IF_PAYMENT_METHOD_PRESENT) {
        checkGooglePaymentMethod(m === ModeGooglePay.ENABLED_IF_PAYMENT_METHOD_PRESENT)
          .then((r) => {
            isGooglePayAvailable = r;
          })
          .catch(() => {
            isGooglePayAvailable = false;
          })
          .then(() => {
            this._updateGoogleBtn();
          });
      }
    }
  };

  submit() {
    this.form && this.form.submit();
  }

  render() {
    return <div id={FORM_CONTAINER_ID} className={this.props.className} />;
  }
}

export default CardElement;
