import { QuizStepKind } from "@web-solutions/core/containers/questions/types";
import { ImageSource } from "@web-solutions/core/interfaces/images";

export enum QuizStepQuestionType {
  SINGLE_IQ = "single_iq",
  SINGLE = "single",
  MULTI = "multi",
  TRANSITION = "transition",
  WELCOME = "welcome",
  INPUT = "input",
  QUESTION = "question",
  IMAGE="image",
}

export interface QuizStepQuestion {
  type: QuizStepQuestionType.SINGLE | QuizStepQuestionType.MULTI;
  title: string;
  subtitle?: string;
  uptitle?: string;
  options?: { [key: string]: string };
  images?: { [key: string]: ImageSource };
  colors?: { [key: string]: string };
  kind?: QuizStepKind;
  limited?: number;
  note_text?: string;
  headImage?: ImageSource;
}

export interface QuizStepTransition {
  type: QuizStepQuestionType.TRANSITION;
  title: string;
  subTitle?: string;
  description?: string;
  descriptionTitle?: string;
  imageCaption?: string;
  image: ImageSource;
  buttonText?: string;
}

export interface QuizStepWelcome {
  type: QuizStepQuestionType.WELCOME;
  title: string;
  subTitle?: string;
  image: ImageSource;
  buttonText?: string;
}

export interface QuizStepInput {
  type: QuizStepQuestionType.INPUT;
  title: string;
  skipText?: string;
  buttonText?: string;
  inputType: "phone" | "text";
}

export interface QuizStepQuestionIq {
  type: QuizStepQuestionType.SINGLE_IQ;
  questionImage: ImageSource;
  answersImages: ImageSource[];
  id?: number;
  correctAnswer?: ImageSource;
  correctAnswerNumber?: number
}

export type QuizStep =
  | QuizStepQuestion
  | QuizStepTransition
  | QuizStepWelcome
  | QuizStepInput
  | QuizStepQuestionIq;
