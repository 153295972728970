//@ts-nocheck
import React, {useRef, useState} from "react";

import {T} from "@web-solutions/module-localization";

import {useAppSelector} from "@web-solutions/store/types"


import {ProductDetails} from "core/store/billing/selectors";
import {Block} from "core/ui-elements";
import {StandalonePayment, StandalonePaymentRef,} from 'core/payment/standalone';

import {useRemoteConfig} from "core/hooks/use-remote-config";

import classes from './style.module.scss';

import {ReactComponent as CheckIcon} from './icons/check.svg';

interface SinglePlanProps {
  product: ProductDetails,
}

export const StandalonePlan: React.FC<SinglePlanProps> = ({product}) => {

  const standalonePaymentRef = useRef<StandalonePaymentRef>(null);
  const locale = useAppSelector((state) => state.locale.locale);

  const { cerebrumSubScreenData } = useRemoteConfig();
  const { textsWidthCheck, totalTitle, hint, featureBlock } = cerebrumSubScreenData.standalonePlan

  console.log('product', product);
  return (
    <Block className={classes.block}>
      {textsWidthCheck[locale]?.map((text, index) => (
        <div id={index === textsWidthCheck[locale]?.length - 2 ? "total-section" : ""} className={classes.item} key={index}>
          <CheckIcon/>
          <p className={classes.text}>{text}</p>
        </div>
      ))}
      <div className={classes.totalSection}>
        <h3 className={classes.totalTitle}>{totalTitle[locale]}</h3>
        <div>
          <span className={classes.priceDiscount}>{product.textLineThrough}</span>
          <span className={classes.totalTitle}>${Boolean(product.trialDays) ? product.trialPriceAmount : product.amount}</span>
          <div className={classes.discountHint}>{product.badgeTitle[locale]}</div>
        </div>
      </div>
      <div className={classes.hint}>
        <T k={hint[locale]}
           tOptions={{...product}}/>
      </div>

      {featureBlock[locale]?.map(({emoji, title, description}) => (
        <div className={classes.featureBlock} key={title}>
          <span className={classes.emoji}>{emoji}</span>
          <div>
            <p className={classes.featureTitle}>{title}</p>
            <p className={classes.featureDescription}>{description}</p>
          </div>
        </div>
      ))}

        <StandalonePayment
          ref={standalonePaymentRef}
          activeProduct={product}
        />
    </Block>
  )
}