import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useFixedButton } from 'core/hooks/use-fixed-button';

import Button from '../button';

import classes from './style.module.scss';

interface Props {
  text: string;
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  productsListSelector?: string;
  buttonsSelector: string;
  productSelector?: string;
  className?: string;
  buttonClassname?: string
  children?: ReactNode
}

const DynamicFixedButtonBlock: React.FC<Props> = ({
  text,
  onButtonClick,
  buttonsSelector,
  className,
  productsListSelector,
  productSelector,
  buttonClassname,
  children
}) => {
  const { fixed } = useFixedButton({
    buttonsSelector,
    productSelector,
    productsListSelector
  });

  return (
    <div className={classNames(classes.fixed, className, {
      [classes.hidden]: !fixed,
    })}
    >
      {children}
      <Button
        id='fixed'
        title={text}
        titleClassName={classes.button_text}
        className={classNames(classes.button, buttonClassname)}
        onClick={onButtonClick}
      />
    </div>
  )
}

export default DynamicFixedButtonBlock;