import queryString from "query-string";

import { ThunkAction } from "redux-thunk";

import { remoteConfigSelector } from "@web-solutions/core/store/remote-config/selectors";
import { UPSALE } from "@web-solutions/core/upsale/store/types";
import {
  doRedirectToChrome,
  REDIRECT_TO_CHROME,
} from "@web-solutions/core/utils/redirect-to-chrome";

//@ts-ignore
import { selectFlow } from "src/store/routing/selectors";

import { SUBSCRIPTION } from "../../constants/routes";

import { routingSlice } from ".";


export const { setCompleted, setStep, setStartRoute } = routingSlice.actions;

export const init =
  (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const { redirectToChrome } = remoteConfigSelector(getState());
    const flow = selectFlow(getState());

    if (redirectToChrome === REDIRECT_TO_CHROME.ENABLED) {
      doRedirectToChrome(redirectToChrome);
    }

    const params = queryString.parse(window.location.search);

    if (params.completed !== undefined) {
      dispatch(setCompleted(true));
    }

    if (params.purchased !== undefined) {
      // dispatch(setPurchase({} as Purchase));
    }

    // if (params.email) {
    //   console.log('if (params.email) {');
    //   dispatch(processEmail(params.email as string));
    // }

    const {
      routing: { isCompleted },
    } = getState();

    dispatch(setStep(0));

    const startRoute = !isCompleted
      ? `/${flow[0]}`
      : await dispatch(getRouteAfterFlow());
    dispatch(setStartRoute(startRoute));

    return Promise.resolve();
  };

export const getRouteAfterFlow =
  (): ThunkAction<string, any, unknown, any> => (dispatch, getState) => {
    const {
      billing: { purchased },
      remoteConfig: { presummaryStack, upsaleFlowMode },
      upsale: { isUpsaleFlowComplete }
    } = getState();

    if (purchased) {
      switch (purchased) {
        case isUpsaleFlowComplete:
          return SUBSCRIPTION.SUCCESS
        case upsaleFlowMode:
          return UPSALE.MAIN
        default:
          return SUBSCRIPTION.SUCCESS
      }
    } else if (presummaryStack?.length) {
      return SUBSCRIPTION.PRESUMMARY;
    } else {
      return SUBSCRIPTION.MAIN;
    }
  };
