import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRemoteConfig } from "@web-solutions/core/hooks/use-remote-config"

import Analytics from "@web-solutions/module-analytics"

import { useQuizNavigation } from '@web-solutions/base-app/hooks/use-quiz-navigation';

export const useNavigateAfterPurchase = () => {
  const navigate = useNavigate();
  const { getRouteAfterPurchased } = useQuizNavigation();
  const { upsaleFlowMode } = useRemoteConfig();

  const navigateNextScreen = useCallback(() => {
    const route = getRouteAfterPurchased();
    upsaleFlowMode && Analytics.trackEvent("upsale_flow", "started");
    navigate(route, { replace: true });
    window.scrollTo(0, 0);
  }, [getRouteAfterPurchased, navigate,upsaleFlowMode])

  return navigateNextScreen
}
