import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { QUIZ } from '@web-solutions/store/types';

import normalizeBlocks from 'core/utils/normalize-blocks';

import { STEPS } from 'src/screens/quiz/questions';
import { RootState } from 'src/store/types';

const ROUTES = Object.keys(STEPS).concat(Object.keys(QUIZ));

const getFlow = (state: RootState) => state.remoteConfig.flow;

export const selectFlowBlocks = createSelector([getFlow], (flow) => {
  let r = normalizeBlocks(flow);
  r = r.map((i) => _intersection(i, ROUTES)).filter((i) => !!i.length);
  return r;
});

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow;
});
