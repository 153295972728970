import {
  QuizStepQuestionIq,
  QuizStepQuestionType,
} from '@web-solutions/base-app/screens/quiz/interfaces';

import { IMAGES } from './images';

export const QUESTIONS: QuizStepQuestionIq[] = [
  {
    questionImage: IMAGES.CARD1,
    answersImages: [
      IMAGES.ANSWER1_1,
      IMAGES.ANSWER1_2,
      IMAGES.ANSWER1_3,
      IMAGES.ANSWER1_4,
      IMAGES.ANSWER1_5,
      IMAGES.ANSWER1_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER1_3,
    correctAnswerNumber: 3,
  },
  {
    questionImage: IMAGES.CARD2,
    answersImages: [
      IMAGES.ANSWER2_1,
      IMAGES.ANSWER2_2,
      IMAGES.ANSWER2_3,
      IMAGES.ANSWER2_4,
      IMAGES.ANSWER2_5,
      IMAGES.ANSWER2_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER2_5,
    correctAnswerNumber: 5,
  },
  {
    questionImage: IMAGES.CARD3,
    answersImages: [
      IMAGES.ANSWER3_1,
      IMAGES.ANSWER3_2,
      IMAGES.ANSWER3_3,
      IMAGES.ANSWER3_4,
      IMAGES.ANSWER3_5,
      IMAGES.ANSWER3_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER3_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD4,
    answersImages: [
      IMAGES.ANSWER4_1,
      IMAGES.ANSWER4_2,
      IMAGES.ANSWER4_3,
      IMAGES.ANSWER4_4,
      IMAGES.ANSWER4_5,
      IMAGES.ANSWER4_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER4_1,
    correctAnswerNumber: 1
  },
  {
    questionImage: IMAGES.CARD5,
    answersImages: [
      IMAGES.ANSWER5_1,
      IMAGES.ANSWER5_2,
      IMAGES.ANSWER5_3,
      IMAGES.ANSWER5_4,
      IMAGES.ANSWER5_5,
      IMAGES.ANSWER5_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER5_5,
    correctAnswerNumber: 5
  },
  {
    questionImage: IMAGES.CARD6,
    answersImages: [
      IMAGES.ANSWER6_1,
      IMAGES.ANSWER6_2,
      IMAGES.ANSWER6_3,
      IMAGES.ANSWER6_4,
      IMAGES.ANSWER6_5,
      IMAGES.ANSWER6_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER6_1,
    correctAnswerNumber: 1
  },
  {
    questionImage: IMAGES.CARD7,
    answersImages: [
      IMAGES.ANSWER7_1,
      IMAGES.ANSWER7_2,
      IMAGES.ANSWER7_3,
      IMAGES.ANSWER7_4,
      IMAGES.ANSWER7_5,
      IMAGES.ANSWER7_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER7_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD8,
    answersImages: [
      IMAGES.ANSWER8_1,
      IMAGES.ANSWER8_2,
      IMAGES.ANSWER8_3,
      IMAGES.ANSWER8_4,
      IMAGES.ANSWER8_5,
      IMAGES.ANSWER8_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER8_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD9,
    answersImages: [
      IMAGES.ANSWER9_1,
      IMAGES.ANSWER9_2,
      IMAGES.ANSWER9_3,
      IMAGES.ANSWER9_4,
      IMAGES.ANSWER9_5,
      IMAGES.ANSWER9_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER9_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD10,
    answersImages: [
      IMAGES.ANSWER10_1,
      IMAGES.ANSWER10_2,
      IMAGES.ANSWER10_3,
      IMAGES.ANSWER10_4,
      IMAGES.ANSWER10_5,
      IMAGES.ANSWER10_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER10_6,
    correctAnswerNumber: 6
  },
  {
    questionImage: IMAGES.CARD11,
    answersImages: [
      IMAGES.ANSWER11_1,
      IMAGES.ANSWER11_2,
      IMAGES.ANSWER11_3,
      IMAGES.ANSWER11_4,
      IMAGES.ANSWER11_5,
      IMAGES.ANSWER11_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER11_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD12,
    answersImages: [
      IMAGES.ANSWER12_1,
      IMAGES.ANSWER12_2,
      IMAGES.ANSWER12_3,
      IMAGES.ANSWER12_4,
      IMAGES.ANSWER12_5,
      IMAGES.ANSWER12_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER12_1,
    correctAnswerNumber: 1
  },
  {
    questionImage: IMAGES.CARD13,
    answersImages: [
      IMAGES.ANSWER13_1,
      IMAGES.ANSWER13_2,
      IMAGES.ANSWER13_3,
      IMAGES.ANSWER13_4,
      IMAGES.ANSWER13_5,
      IMAGES.ANSWER13_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER13_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD14,
    answersImages: [
      IMAGES.ANSWER14_1,
      IMAGES.ANSWER14_2,
      IMAGES.ANSWER14_3,
      IMAGES.ANSWER14_4,
      IMAGES.ANSWER14_5,
      IMAGES.ANSWER14_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER14_5,
    correctAnswerNumber: 5
  },
  {
    questionImage: IMAGES.CARD15,
    answersImages: [
      IMAGES.ANSWER15_1,
      IMAGES.ANSWER15_2,
      IMAGES.ANSWER15_3,
      IMAGES.ANSWER15_4,
      IMAGES.ANSWER15_5,
      IMAGES.ANSWER15_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER15_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD16,
    answersImages: [
      IMAGES.ANSWER16_1,
      IMAGES.ANSWER16_2,
      IMAGES.ANSWER16_3,
      IMAGES.ANSWER16_4,
      IMAGES.ANSWER16_5,
      IMAGES.ANSWER16_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER16_3,
    correctAnswerNumber: 3
  },
  {
    questionImage: IMAGES.CARD17,
    answersImages: [
      IMAGES.ANSWER17_1,
      IMAGES.ANSWER17_2,
      IMAGES.ANSWER17_3,
      IMAGES.ANSWER17_4,
      IMAGES.ANSWER17_5,
      IMAGES.ANSWER17_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER17_1,
    correctAnswerNumber: 1
  },
  {
    questionImage: IMAGES.CARD18,
    answersImages: [
      IMAGES.ANSWER18_1,
      IMAGES.ANSWER18_2,
      IMAGES.ANSWER18_3,
      IMAGES.ANSWER18_4,
      IMAGES.ANSWER18_5,
      IMAGES.ANSWER18_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER18_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD19,
    answersImages: [
      IMAGES.ANSWER19_1,
      IMAGES.ANSWER19_2,
      IMAGES.ANSWER19_3,
      IMAGES.ANSWER19_4,
      IMAGES.ANSWER19_5,
      IMAGES.ANSWER19_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER19_5,
    correctAnswerNumber: 5
  },
  {
    questionImage: IMAGES.CARD20,
    answersImages: [
      IMAGES.ANSWER20_1,
      IMAGES.ANSWER20_2,
      IMAGES.ANSWER20_3,
      IMAGES.ANSWER20_4,
      IMAGES.ANSWER20_5,
      IMAGES.ANSWER20_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER20_6,
    correctAnswerNumber: 6
  },
  {
    questionImage: IMAGES.CARD21,
    answersImages: [
      IMAGES.ANSWER21_1,
      IMAGES.ANSWER21_2,
      IMAGES.ANSWER21_3,
      IMAGES.ANSWER21_4,
      IMAGES.ANSWER21_5,
      IMAGES.ANSWER21_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER21_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD22,
    answersImages: [
      IMAGES.ANSWER22_1,
      IMAGES.ANSWER22_2,
      IMAGES.ANSWER22_3,
      IMAGES.ANSWER22_4,
      IMAGES.ANSWER22_5,
      IMAGES.ANSWER22_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER22_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD23,
    answersImages: [
      IMAGES.ANSWER23_1,
      IMAGES.ANSWER23_2,
      IMAGES.ANSWER23_3,
      IMAGES.ANSWER23_4,
      IMAGES.ANSWER23_5,
      IMAGES.ANSWER23_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER23_6,
    correctAnswerNumber: 6
  },
  {
    questionImage: IMAGES.CARD24,
    answersImages: [
      IMAGES.ANSWER24_1,
      IMAGES.ANSWER24_2,
      IMAGES.ANSWER24_3,
      IMAGES.ANSWER24_4,
      IMAGES.ANSWER24_5,
      IMAGES.ANSWER24_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER24_6,
    correctAnswerNumber: 6
  },
  {
    questionImage: IMAGES.CARD25,
    answersImages: [
      IMAGES.ANSWER25_1,
      IMAGES.ANSWER25_2,
      IMAGES.ANSWER25_3,
      IMAGES.ANSWER25_4,
      IMAGES.ANSWER25_5,
      IMAGES.ANSWER25_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER25_4,
    correctAnswerNumber: 4
  },
  {
    questionImage: IMAGES.CARD26,
    answersImages: [
      IMAGES.ANSWER26_1,
      IMAGES.ANSWER26_2,
      IMAGES.ANSWER26_3,
      IMAGES.ANSWER26_4,
      IMAGES.ANSWER26_5,
      IMAGES.ANSWER26_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER26_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD27,
    answersImages: [
      IMAGES.ANSWER27_1,
      IMAGES.ANSWER27_2,
      IMAGES.ANSWER27_3,
      IMAGES.ANSWER27_4,
      IMAGES.ANSWER27_5,
      IMAGES.ANSWER27_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER27_6,
    correctAnswerNumber: 6
  },
  {
    questionImage: IMAGES.CARD28,
    answersImages: [
      IMAGES.ANSWER28_1,
      IMAGES.ANSWER28_2,
      IMAGES.ANSWER28_3,
      IMAGES.ANSWER28_4,
      IMAGES.ANSWER28_5,
      IMAGES.ANSWER28_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER28_3,
    correctAnswerNumber: 3
  },
  {
    questionImage: IMAGES.CARD29,
    answersImages: [
      IMAGES.ANSWER29_1,
      IMAGES.ANSWER29_2,
      IMAGES.ANSWER29_3,
      IMAGES.ANSWER29_4,
      IMAGES.ANSWER29_5,
      IMAGES.ANSWER29_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER29_2,
    correctAnswerNumber: 2
  },
  {
    questionImage: IMAGES.CARD30,
    answersImages: [
      IMAGES.ANSWER30_1,
      IMAGES.ANSWER30_2,
      IMAGES.ANSWER30_3,
      IMAGES.ANSWER30_4,
      IMAGES.ANSWER30_5,
      IMAGES.ANSWER30_6,
    ],
    type: QuizStepQuestionType.SINGLE_IQ,
    correctAnswer: IMAGES.ANSWER30_6,
    correctAnswerNumber: 6
  },
];
